import React, {  useEffect, useRef } from "react";
// import { HashLink as Link } from "react-router-hash-link";
import { Helmet } from "react-helmet";
function Products() {

  const tref = useRef(null);  
  const [index, setIndex] = React.useState(0); 
  const myRef = useRef(null);
  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      5500 // every 3 seconds
    );
    let ul = window.location.href;
    if (ul.includes("#insight")) {
      if (window.innerWidth > 767) {
        window.scrollTo({
          top: 60,
          behavior: "smooth",
        });
        console.log("system " + window.innerWidth);
      } else {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        console.log("Mobile " + window.innerWidth);
      }
    } 
    else if (ul.includes("#sociallistening")) {
      if (window.innerWidth > 767) {
        window.scrollTo({
          top: 300,
          behavior: "smooth",
        });
        console.log("system " + window.innerWidth);
      } else {
        window.scrollTo({
          top: 500,
          behavior: "smooth",
        });
        console.log("Mobile " + window.innerWidth);
      }
    } 
    else if (ul.includes("#socialmediamonitoring")) {
      if (window.innerWidth > 767) {
        window.scrollTo({
          top: 650,
          behavior: "smooth",
        });
        console.log("system " + window.innerWidth);
      } else {
        window.scrollTo({
          top: 1000,
          behavior: "smooth",
        });
        console.log("Mobile " + window.innerWidth);
      }
    }
    else if (ul.includes("#appanalysis")) {
      if (window.innerWidth > 767) {
        window.scrollTo({
          top: 900,
          behavior: "smooth",
        });
        console.log("system " + window.innerWidth);
      } else {
        window.scrollTo({
          top: 1500,
          behavior: "smooth",
        });
        console.log("Mobile " + window.innerWidth);
      }
    }
    else if (ul.includes("#campaignanalyzer")) {
      if (window.innerWidth > 767) {
        window.scrollTo({
          top: 1250,
          behavior: "smooth",
        });
        console.log("system " + window.innerWidth);
      } else {
        window.scrollTo({
          top: 2000,
          behavior: "smooth",
        });
        console.log("Mobile " + window.innerWidth);
      }
    }
    else if (ul.includes("#casemanagement")) {
      if (window.innerWidth > 767) {
        window.scrollTo({
          top: 1475,
          behavior: "smooth",
        });
        console.log("system " + window.innerWidth);
      } else {
        window.scrollTo({
          top: 2500,
          behavior: "smooth",
        });
        console.log("Mobile " + window.innerWidth);
      }
    }

    return () => clearTimeout(intervalId);
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>evalmyBRAND's Moto & Vision | evalmyBRAND </title>
        <meta
          name="description"
          content="evalmyBRAND is unified social listening platform which helps brands to improve the targeting accuracy and automates the management of social media communications."
        />
        <meta name="keywords" content="evalmyBRAND,About evalmyBRAND" />
      </Helmet> 
      <div id="about" title="aboutevalmyBRAND">

        <div className="home-page-section positon-sticky" id="header">
          <nav className="navbar navbar-expand-lg" id="navbar">
            <div className="container-fluid">
              <a className="navbar-brand" href="https://evalmybrand.com/">
                <img
                  src="pages/images/evalmybrand-Logo.png"
                  alt="evalmyBRANDlogo"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  {/* <li className="nav-item">
                    <a
                      className="nav-link"
                      aria-current="page"
                      href="https://evalmybrand.com/"
                    >
                      Home
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a className="nav-link" href="/about">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      href="/features"
                    >
                      Features
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link"
                      href="/pricing"
                    >
                      Pricing
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="https://blog.evalmybrand.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      News & Blog
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/getstarted">
                      Contact Us
                    </a>
                  </li>
                </ul>
                <div className="nav-btns d-flex hide">
                  <a
                    href="https://portal.evalmybrand.com"
                    className="mt-1 nav-link active"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Login
                  </a>
                  {/* <a href="/sales">
                  <button className="btn btn-primary">Request Demo</button>
                </a> */}
                </div>
              </div>
            </div>
          </nav> 
          {/* <a href="/">
            <div className="left-arrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                className="bi bi-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                />
              </svg>
            </div>
          </a> */}
          {/* <div className="in-ab-text">
            <div className="container text-center">
              <h2 className="text-center mb-3">About Us</h2> 
            </div>
          </div> */}
        </div>
      </div>
      {/* <div className="destination mt-5 pt-3">
        <div className="container mb-3"> 
          <p>
            Brands are built on solid ideas, plans, and execution. The idea is
            fundamental to the existence of a company. As part of planning and
            execution, the brand must develop products and deliver them to their
            customers. The world we live in today is driven by data, and
            data-driven decision- making is at the heart of planning and
            execution.{" "}
          </p>
          <p>
            We at evalmyBRAND utilise powerful AI Algorithms, Natural Language
            Processing and Sentiment analysis to provide insights about your
            brand to accelerate planning and execution. Thus, fueled by data &
            insights from our powerful AI base model, evalmyBRAND helps you reach your
            brand goals.{" "}
          </p>
        </div>
      </div> */}

      <div className="container insight pt-5">
        <div className="row" id="insight">

          <div className="col-md-8">
            <div className="ProductTextBox">
              <div className="growth growthInfo">
                <h2 className="connect">
                  Insights & Analytics
                </h2>
                <div className="about mobileImg" title="aboutevalmyBRAND">
                  <div id="videos-part">
                    <img src="/pages/images/insight.png" alt='Feature' />
                  </div>
                </div>
                <p className="metrics productList">
                  Powered by advanced AI technology, this product leverages sentiment analysis and machine learning algorithms to provide comprehensive insights and analytics. It analyzes social media conversations, online discussions, and customer feedback to identify key topics, sentiment trends, and consumer opinions. By understanding sentiment and extracting valuable insights, businesses can make data-driven decisions to improve their brand's performance.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="about" title="aboutevalmyBRAND">
              <div id="videos-part">
                <img src="/pages/images/insight.png" alt='Feature'/>
              </div>
            </div>
          </div>
        </div>
        <div className="row" id="sociallistening">
          <div className="col-md-4">
            <div className="about" title="aboutevalmyBRAND">
              <div id="videos-part">
                <img src="/pages/images/sociallisten.png" alt='Feature'/>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="ProductTextBox">
              <div className="growth growthInfo growthInfoLeft">
                <h2 className="connect">
                  Social Listening
                </h2>
                <div className="about mobileImg" title="aboutevalmyBRAND">
              <div id="videos-part">
                <img src="/pages/images/sociallisten.png" alt='Feature'/>
              </div>
            </div>
                <div className="text-end">
                  <p className="metrics productList">
                    Our AI-driven social listening product employs natural language processing and sentiment analysis to monitor and capture online conversations across social media platforms. It enables businesses to gain real-time insights into what customers are saying about their brand, products, and industry. By identifying key topics and sentiments, businesses can understand public perception, track trends, and effectively engage with their target audience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" id="socialmediamonitoring">

          <div className="col-md-8">
            <div className="ProductTextBox">
              <div className="growth growthInfo">
                <h2 className="connect">
                  Social Media Monitoring
                </h2>
                <div className="about mobileImg" title="aboutevalmyBRAND">
              <div id="videos-part">
                <img src="/pages/images/socialmoniter.png" alt='Feature'/>
              </div>
            </div>
                <p className="metrics productList">
                  Using advanced AI algorithms, our social media monitoring solution continuously tracks brand presence and activities across various social media platforms. It analyzes user engagement, sentiment, and key topics to provide actionable insights. By understanding customer sentiment and identifying trending topics, businesses can optimize their social media strategy, enhance their online reputation, and engage with their audience more effectively.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="about" title="aboutevalmyBRAND">
              <div id="videos-part">
                <img src="/pages/images/socialmoniter.png" alt='Feature' />
              </div>
            </div>
          </div>
        </div>
        <div className="row" id="appanalysis">
          <div className="col-md-4">
            <div className="about" title="aboutevalmyBRAND">
              <div id="videos-part">
                <img src="/pages/images/appa.png" alt='Feature'/>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="ProductTextBox">
              <div className="growth growthInfo growthInfoLeft">
                <h2 className="connect">
                  App Analysis
                </h2>
                <div className="about mobileImg" title="aboutevalmyBRAND">
              <div id="videos-part">
                <img src="/pages/images/appa.png" alt='Feature'/>
              </div>
            </div>
                <div className="text-end">
                  <p className="metrics productList">
                    Our AI-powered app analysis tool examines user engagement, sentiment, and key topics within mobile applications. By leveraging natural language processing and machine learning, it provides deep insights into user feedback, reviews, and ratings. This analysis helps businesses identify areas of improvement, understand user preferences, and optimize the user experience to drive higher app engagement and satisfaction.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" id="campaignanalyzer">
          <div className="col-md-8">
            <div className="ProductTextBox">
              <div className="growth growthInfo">
                <h2 className="connect">
                  Campaign Analyzer
                </h2>
                <div className="about mobileImg" title="aboutevalmyBRAND">
              <div id="videos-part">
                <img src="/pages/images/comapin.png" alt='Feature'/>
              </div>
            </div>
                <p className="metrics productList">
                  Equipped with AI capabilities, our campaign analyzer product evaluates the effectiveness of marketing campaigns across multiple channels. It utilizes sentiment analysis to gauge public response and identifies key topics of conversation related to the campaigns. By analyzing campaign performance metrics and sentiment trends, businesses can refine their marketing strategies, target specific audiences, and optimize future campaigns for better results.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="about" title="aboutevalmyBRAND">
              <div id="videos-part">
                <img src="/pages/images/comapin.png" alt='Feature'/>
              </div>
            </div>
          </div>
        </div>
        <div className="row" id="casemanagement">
          <div className="col-md-4">
            <div className="about" title="aboutevalmyBRAND">
              <div id="videos-part">
                <img src="/pages/images/casemangement.png" alt='Feature'/>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="ProductTextBox">
              <div className="growth growthInfo growthInfoLeft">
                <h2 className="connect">
                  Case Management
                </h2>
                <div className="about mobileImg" title="aboutevalmyBRAND">
              <div id="videos-part">
                <img src="/pages/images/casemangement.png" alt='Feature'/>
              </div>
            </div>
                <div className="text-end">
                  <p className="metrics productList">
                    Our AI-driven case management solution streamlines customer inquiries, complaints, and support tickets. By employing natural language processing and sentiment analysis, it automatically categorizes and prioritizes cases based on sentiment and key topics. This enables businesses to provide timely and personalized support, efficiently resolve customer issues, and improve overall customer satisfaction.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      {/* <div className="team">
        <div className="container">
          <h4 className="text-center mb-4">Our Team</h4>
          <h3 className="text-center mb-5">
            True Leaders Don't Create Followers. They Create More Leaders.
          </h3>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-3 mb-3">
              <div className="team-member">
                <img src="/pages/images/team-1.png" alt="evalmyteam" />
              </div>
            </div>
            <div className="col-md-12 col-lg-3 mb-3">
              <div className="team-member">
                <img src="/pages/images/team-1.png" alt="evalmyteam" />
              </div>
            </div>
            <div className="col-md-12 col-lg-3 mb-3">
              <div className="team-member">
                <img src="/pages/images/team-1.png" alt="evalmyteam" />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="footer">
        {/* <div className="social-img"></div> */}
        <div className="container" style={{ paddingBottom: '40px' }}>
          <div className="row ">
            <div className="col-lg-2"></div>
            <div className="col-lg-6">
              <div className="d-flex br">
                <img
                  src="pages/images/gdpr.png"
                  alt="gdprlogo"
                  className="br-logo"
                />
                <img
                  src="pages/images/iso.png"
                  alt="isologo"
                  className="br-logo"
                />
                <img
                  src="pages/images/soc.png"
                  alt="soclogo"
                  className="br-logo"
                />
                <img
                  src="pages/images/skyhigh.png"
                  alt="skyhighlogo"
                  className="br-logo"
                />
              </div>
            </div>
          </div>
          <div className="row   justify-content-center mt-5">
            <div className="col-lg-3 col-md-6">
              <div className="s-brand">
                <a href="/">
                  <img
                    src="pages/images/evalmybrand.png"
                    className="s-img"
                    alt="evalmyBRANDmoblogo"
                  />
                </a>
                <p className="mission mt-2 mb-2">
                  Our mission is to empower you with the knowledge and tools you need to navigate the rapidly changing landscape of social media and customer experience.
                </p>
                <div className="social-imp">
                  <a
                    href="https://www.facebook.com/people/evalmyBRAND/100086047461629/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="pages/images/social-facebook.png"
                      alt="facebooklogo"
                      className="social"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/evalmybrand/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="pages/images/social-linkedin.png"
                      alt="linkedinlogo"
                      className="social"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/evalmybrand/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="pages/images/social-insta.png"
                      alt="instagramlogo"
                      className="social"
                    />
                  </a>
                  <a href=" https://twitter.com/evalmyBrand" target="_blank">
                    <img
                      src="pages/images/social-twitter.png"
                      alt="twitterlogo"
                      className="social"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCOs9P0nxGKD8uCDWWHRxgkw/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="pages/images/social-youtube.png"
                      alt="youtubelogo"
                      className="social"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="main-detailes mb-3" id="main-m">
                <a href="https://evalmybrand.com/">
                  <h3>Company</h3>
                </a>
                <a href="/about">
                  <p>About evalmyBRAND</p>
                </a>
                <a href="/pricing">
                  <p>Pricing</p>
                </a>
                <a href="https://blog.evalmybrand.com/" target="_blank" rel="noreferrer" >
                  <p>News & Blog</p>
                </a>
                <a href="/#favourite">
                  <p >Customers</p>
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="main-detailes">
                <a href="features">
                  <h3>Features</h3>
                </a>
                <a href="features">
                  <p>Insights & Analytics</p>
                </a>
                <a href="features">
                  <p>Social Listening</p>
                </a>
                <a href="features">
                  <p>Social Media Monitoring</p>
                </a>
                <a href="features">
                  <p>App Analysis</p>
                </a>
                <a href="features">
                  <p>Campaign Analyzer</p>
                </a>
                <a href="features">
                  <p>Case Management</p>
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="main-detailes mb-5">
                <h3>Support & Legal</h3>
                <a href="/faqs" target="_blank" rel="noreferrer" >
                  <p>FAQs</p>
                </a>
                <a href="/getstarted">
                  <p>Contact Us</p>
                </a>
                <a href="/terms" target="_blank" className="nav-link active">
                  <p className="d-1">Terms of Use</p>
                </a>
                <a href="/policies" target="_blank" className="nav-link active">
                  <p className="d-1">Privacy Policy</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr style={{ marginBottom: '0.5rem' }} />
        <div className="container">
          <div className="footer-detailes">
            <div className="d-flex detailes">
              <div className="copy">
                <p style={{ marginBottom: '0.5rem' }}>© evalmyBRAND 2023. All Rights Reserved </p>
                <p style={{ display: "none" }}>01.09</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Products;
