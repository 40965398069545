import React from "react";
// import { HashLink as Link } from "react-router-hash-link";
import { Helmet } from "react-helmet";
function Terms() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>evalmyBRAND's Moto & Vision | evalmyBRAND </title>
        <meta
          name="description"
          content="evalmyBRAND is unified social listening platform which helps brands to improve the targeting accuracy and automates the management of social media communications."
        />
        <meta name="keywords" content="evalmyBRAND,About evalmyBRAND" />
      </Helmet>

      <div id="about" title="aboutevalmyBRAND">

<div className="home-page-section positon-sticky" id="header">
  <nav className="navbar navbar-expand-lg" id="navbar">
    <div className="container-fluid">
      <a className="navbar-brand" href="https://evalmybrand.com/">
        <img
          src="pages/images/evalmybrand-Logo.png"
          alt="evalmyBRANDlogo"
        />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse"
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          {/* <li className="nav-item">
            <a
              className="nav-link"
              aria-current="page"
              href="https://evalmybrand.com/"
            >
              Home
            </a>
          </li> */}
          <li className="nav-item">
            <a className="nav-link" href="/about">
              About Us
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link "
              href="features"
            >
              Features
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link"
              href="/pricing"
            >
              Pricing
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="https://blog.evalmybrand.com/"
              target="_blank"
              rel="noreferrer"
            >
              News & Blog
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/getstarted">
              Contact Us
            </a>
          </li>
        </ul>
        <div className="nav-btns d-flex hide">
          <a
            href="https://portal.evalmybrand.com"
            className="mt-1 nav-link active"
            target="_blank"
            rel="noreferrer"
          >
            Login
          </a>
          {/* <a href="/sales">
          <button className="btn btn-primary">Request Demo</button>
        </a> */}
        </div>
      </div>
    </div>
  </nav>
</div>
</div>
      <div class="terms">
        <div class="container">
          <h1 class="text-center mt-5 mb-5">TERMS OF USE</h1>
          <p class="mb-4 text-justify">
            Welcome to evalmyBRAND(hereafter referred to as Company or Us or
            Entity), owned and operated by the Aimlytics Technologies Pvt.
            Ltd.,with its registered office located at10th floor, SanaliSpazio,
            Madhapur, Hyderabad - 500081 Telangana, INDIA.The portal/service is
            offered to You conditioned on Your acceptance without modification
            of the Terms, Conditions, and notices contained.
          </p>
          <dl class="text-justify">
            <dt>
              <span class="terms">1.</span>INTRODUCTION
            </dt>
            <dd>
              <span class="term">1.1 </span>You may use the Service only if You
              can form a binding contract with Us, and only in compliance with
              this Agreement and all applicable local, state, national, and
              international laws, rules and regulations.
            </dd>
            <dd>
              <span class="term">1.2</span>By clicking on the "Accept" button at
              the end of the Agreement acceptance form, Users agree to be bound
              by the Terms and Conditions of this Agreement. Please read this
              entire Agreement carefully before accepting its Terms. When You
              undertake any activity on the Website/ Portal,You agree to accept
              these Terms and Conditions.{" "}
            </dd>
            <dd>
              <span class="term">1.3</span>In using this Website/ Portal, You
              are deemed to have read and agreed to the following Terms and
              Conditions set forth herein. Any incidental documents and links
              mentioned shall be accepted jointly with these Terms. You agree to
              use the Website only in strict interpretation and acceptance of
              these Terms, and any actions or commitments made without regard to
              these Terms shall be at Your own risk. These Terms and Conditions
              form part of the Agreement between the Users and Us. By accessing
              this Website/Portal, and/or undertaking to perform a Service
              provided by Us indicates Your understanding, agreement to and
              acceptance of the disclaimer notice and the full Terms and
              Conditions contained herein.
            </dd>

            <dt>
              <span class="terms">2.</span>ELIGIBILITY OF THE USER
            </dt>
            <dd>
              <span class="term">2.1 </span>You may use the Service only if You
              can form a binding contract with Us, and only in compliance with
              this Agreement and all applicable local, state, national, and
              international laws, rules and regulations.
            </dd>
            <dd>
              <span class="term">2.2</span>Unauthorized Users are strictly
              prohibited from accessing or attempting to access, directly or
              indirectly, the Platform. Any such unauthorized use is strictly
              forbidden and shall constitute a violation of applicable state and
              local laws.{" "}
            </dd>
            <dd>
              <span class="term">2.3</span>Our Website/Portal may, in its sole
              discretion, refuse to offer access to or use of the Platform to
              any person or entity, and change its eligibility criteria at any
              time. This provision is void where prohibited by law and the right
              to access the Website is revoked in such jurisdictions.
            </dd>

            <dt>
              <span class="terms">3.</span>SERVICES OFFERED BY THE PLATFORM
            </dt>
            <dd>
              <span class="term">3.1 </span>We provide the Users with a Platform
              to [SPECIFY THE SERVICES].
            </dd>

            <dt>
              <span class="terms">4.</span>YOU AGREE AND CONFIRM
            </dt>
            <dd>
              <span class="term">4.1 </span>That You will use the Services
              provided by Our Platform, its affiliates and contracted
              companies(if any), for lawful purposes only and comply with all
              applicable laws and regulations while using the Platform.
            </dd>
            <dd>
              <span class="term">4.2</span>That You will provide authentic and
              true information in all instances where such information is
              requested of You. We reserve the right to confirm and validate the
              information and other details provided by You at any point in
              time. If upon confirmation Your details are found not to be true
              (wholly or partly), We have the right in Our sole discretion to
              reject the registration and debar You from using the Services of
              Our Platform and/or other affiliated websites without prior
              intimation whatsoever.{" "}
            </dd>
            <dd>
              <span class="term">4.3</span>That You are accessing the Services
              available on this Website/Portal and transacting at Your sole risk
              and are using Your best and prudent judgment before entering into
              any dealings through this Platform.
            </dd>
            <dd>
              <span class="term">4.4</span>You agree to not post or transmit(if
              given access to – future scope of work) any unlawful, threatening,
              abusive, libelous, defamatory, obscene, vulgar, pornographic,
              profane or indecent information or description/image/text/graphic
              of any kind, including without limitation any transmissions
              constituting or encouraging conduct that would constitute a
              criminal offense, give rise to civil liability or otherwise
              violate any local, state, national, or international law.
            </dd>
            <dd>
              <span class="term">4.5</span>You agree to not post or transmit(if
              given access to – future scope of work) any information, software,
              or other material which violates or infringes the rights of
              others, including material which is an invasion of privacy or
              publicity rights or which is protected by copyright, trademark or
              other proprietary right, or derivative works with respect thereto,
              without first obtaining permission from the owner or right holder.
            </dd>
            <dd>
              <span class="term">4.6</span>You agree to not alter, damage or
              delete any Content or other communications(if given access to –
              future scope of work) that are not Your own Content or to
              otherwise interfere with the ability of others to access Our
              Platform.
            </dd>
            <dd>
              <span class="term">4.7</span>You agree to indemnify and keep
              indemnified the Company from all claims/losses (including
              advocates’ fees for defending/prosecuting any case) that may arise
              against the Company due to acts/omission on the part of the User.
            </dd>

            <dt>
              <span class="terms">5.</span>WARRANTIES, REPRESENTATION AND
              UNDERTAKINGS OF USER
            </dt>
            <dd>
              <span class="term">5.1 </span>The User warrants and represents
              that all obligations narrated under this Agreement are legal,
              valid, binding and enforceable in law against the User.
            </dd>
            <dd>
              <span class="term">5.2</span>The User agrees that there are no
              proceedings pending against the User, which may have a material
              adverse effect on its ability to perform and meet the obligations
              under this Agreement.{" "}
            </dd>
            <dd>
              <span class="term">5.3</span>The User agrees that itshall, at all
              times, ensure compliance with all the requirements applicable to
              its business and for the purposes of this Agreement including but
              not limited to intellectual property rights, value-added tax,
              excise and import duties, etc. It further declares and confirms
              that it has paid and shall continue to discharge all its
              obligations towards statutory authorities.
            </dd>
            <dd>
              <span class="term">5.4</span>The User agrees that it has adequate
              rights under relevant laws including but not limited to various
              intellectual property legislation(s) to enter into this Agreement
              with the Company and perform the obligations contained herein and
              that it has not violated/infringed any intellectual property
              rights of any third party.
            </dd>
            <dd>
              <span class="term">5.5</span>The User agrees that appropriate
              disclaimers and Terms of use on the Company’s Website/Portal shall
              be placed by the Company.
            </dd>

            <dt>
              <span class="terms">6.</span>INTELLECTUAL PROPERTY RIGHTS
            </dt>
            <dd>
              <span class="term">6.1 </span>The User expressly authorizes the
              Company to use its trademarks/copyrights/designs/logos and other
              intellectual property owned and/or licensed by it for the purpose
              of reproduction on the Platform and at such other places as the
              Company may deem necessary. It is expressly agreed and clarified
              that, except as specified agreed in this Agreement, each Party
              shall retain all right, title and interest in their respective
              trademarks and logos and that nothing contained in this Agreement,
              nor the use of the trademarks/logos in the publicity, advertising,
              promotional or other material in relation to the Services shall be
              construed as giving to any Party any right, title or interest of
              any nature whatsoever to any of the other Party’s trademarks
              and/or logos.
            </dd>
            <dd>
              <span class="term">6.2</span>The Company’s Website and other
              Platforms, and the information and materials that it contains, are
              the property of the Company and its licensors, and are protected
              from unauthorized copying and dissemination by copyright law,
              trademark law, international conventions, and other intellectual
              property laws. All the Company’s product names and logos are
              trademarks or registered trademarks. Nothing contained on the
              Company’s Website should be interpreted as granting, by
              implication, estoppel, or otherwise, any license or right to use
              the Company’s Website or any materials displayed on the Company’s
              Website, through the use of framing or otherwise, except: (a) as
              expressly permitted by these Terms and Conditions; or (b) with the
              prior written consent of the Company. The User shall not attempt
              to override or circumvent any of the usage rules or restrictions
              on the Website.
            </dd>
            <dd>
              <span class="term">6.3</span>Except as otherwise expressly granted
              to You in writing, We do not grant You any other express or
              implied right or license to the Services, Our Content or Our
              intellectual property rights.
            </dd>
            <dd>
              <span class="term">6.4</span>Proprietary Rights. Subject to the
              limited rights expressly granted in this Agreement, the Company
              reserves all rights, title and interest in and to the Service,
              including all related intellectual property rights. No rights are
              granted to the User in this Agreement other than as expressly
              outlined in this Agreement.
            </dd>

            <dt>
              <span class="terms">7.</span>DATA
            </dt>
            <dd>
              <span class="term">7.1 </span>"Data" means any and all
              identifiable information about Users, their brands’ social
              insights & analyticsrepresented on the portal and their affiliates
              generated or collected by the Company or the User, including but
              not limited to the User’s name, email addresses, Services availed,
              phone numbers, and the User’s preferences and tendencies. The User
              agrees that it will only use the Data in complying with its
              obligations in this Agreement.
            </dd>
            <dd>
              <span class="term">7.2</span>The User represents, warrants, and
              covenants that it will not resell or otherwise disclose any Data
              to any third party, in whole or in part, for any purpose
              whatsoever.
            </dd>
            <dd>
              <span class="term">7.3</span>The User acknowledges that the
              Services may contain information which is designated as
              confidential by the Company and that You shall not disclose such
              information without the Company’s prior written consent.
            </dd>
            <dd>
              <span class="term">7.4</span>By submitting Content, the User
              hereby irrevocably grantsUs a perpetual, irrevocable,
              nonexclusive, royalty-free right to use the Content for any
              purpose including API partnerships with third parties and in any
              media existing now or in future. The User irrevocably waives, and
              causes to be waived, any claims and assertions of moral rights or
              attribution with respect to the User’s Content brought against Us
              by any third-party services or their users.
            </dd>
            <dd>
              <span class="term">7.5</span>We may obtain business addresses,
              phone numbers, and other contact information from third-party
              vendors who obtain their Data from public sources. We have no
              control over, and make no representation or endorsement regarding
              the accuracy, relevancy, copyright compliance, legality,
              completeness, timeliness or quality of any products, services,
              advertisements and other Content appearing in or linked to the
              Services.
            </dd>

            <dt>
              <span class="terms">8.</span>RELATIONSHIP
            </dt>
            <dd>
              <span class="term">8.1 </span>Nothing in this Agreement will be
              construed as creating a relationship of partnership, joint
              venture, agency, or employment between the Parties. The Company
              shall not be responsible for the acts or omissions of the User,
              and the User shall not represent the Company, neither does it have
              any power or authority to speak for, represent, bind, or assume
              any obligation on behalf of the Company.
            </dd>

            <dt>
              <span class="terms">9.</span>INDEMNITY
            </dt>
            <dd>
              <span class="term">9.1 </span>The User indemnifies and shall hold
              indemnified the Company, its partners, officers, employees,
              representatives, and agents from and against all losses, damages,
              claims, suits, legal proceedings and otherwise howsoever arising
              from or in connection with any claim, including but not limited to
              claims for any infringement of any intellectual property rights or
              any other rights of any third party or of law, concerning quality,
              quantity and any claim in relation to the User’s products, the
              breach of any of the User’s warranties, representations or
              undertakings or in relation to the non-fulfillment of any of its
              obligations under this Agreement or Terms of use of Company’s
              Website or any obligations arising out of the User infringing any
              applicable laws, regulations including but not limited to
              intellectual property rights, or taxes,etc. This clause shall
              survive the termination or expiration of this Agreement.
            </dd>

            <dt>
              <span class="terms">10.</span>INDEMNITY
            </dt>
            <dd>
              <span class="term">10.1 </span>You expressly hereby release and
              waive all claims against the Company, and its subsidiaries,
              affiliates, officers, agents, licensors, co-branders or other
              partners, and employees from any and all liability for claims,
              damages (actual and/or consequential), costs and expenses
              (including litigation costs and attorneys' fees) of every kind and
              nature, arising from or in any way related to Your use of the
              Company’s Website. You understand that any fact relating to any
              matter covered by this release may be found to be other than now
              believed to be true and You accept and assume the risk of such
              possible differences in fact. In addition, You expressly waive and
              relinquish any and all rights and benefits which You may have
              under any other state or federal statute or common law principle
              of similar effect, to the fullest extent permitted by law.
            </dd>

            <dt>
              <span class="terms">11.</span>LIMITATION OF LIABILITY
            </dt>
            <dd>
              <span class="term">11.1 </span>It is expressly agreed by the User
              that the Company shall under no circumstances be liable or
              responsible for any loss, injury or damage to the User or any
              other Party whomsoever, arising on account of any transaction
              under this Agreement.
            </dd>
            <dd>
              <span class="term">11.2</span>The User agrees and acknowledges
              that it shall be solely liable for any claims, damages, or
              allegations arising out of the Services through thePlatform and
              shall hold the Company harmless and indemnified against all such
              claims and damages. Further, the Company shall not be liable for
              any claims or damages arising out of any negligence, misconduct,
              or misrepresentation by the User or any of its representatives.{" "}
            </dd>
            <dd>
              <span class="term">11.3</span>The Company under no circumstances
              shall be liable to the User for loss and/or anticipated loss of
              profits, or for any direct or indirect, incidental, consequential,
              special or exemplary damages arising from the subject matter of
              this Agreement, regardless of the type of claim and even if the
              User has been advised of the possibility of such damages, such as,
              but not limited to loss of revenue or anticipated profits or loss
              of business, unless such loss or damages is proven by the User to
              have been deliberately caused by the Company.
            </dd>

            <dt>
              <span class="terms">12.</span>TERMINATION
            </dt>
            <dd>
              <span class="term">12.1 </span>This Agreement may be terminated by
              the Company if the User commits a material breach of any
              representation, obligation, covenant, warranty or term of this
              Agreement and the same is not cured within 30 days after written
              notice given by the Company, if a petition for insolvency is filed
              against the Useror if the User is in infringement of third-party
              rights, including intellectual property rights.
            </dd>
            <dd>
              <span class="term">12.2</span>This Agreement may be terminated
              without reason by either Party after serving upon the other a
              written notice of thirty (30) days. The Agreement shall stand
              terminated after expiry of such period.
            </dd>

            <dt>
              <span class="terms">13.</span>EFFECTS OF TERMINATION
            </dt>
            <dd>
              <span class="term">13.1 </span>In the event of termination/expiry
              of this Agreement, the Company shall remove and shall discontinue
              the Services provided to the User on its Platform with immediate
              effect. Any data related to the user shall not be stored by the
              company for not more than 30 days and shall be deleted thereafter.
            </dd>
            <dd>
              <span class="term">13.2</span>The Company shall not be liable for
              any loss or damages (direct, indirect or inconsequential) incurred
              by the User by virtue of termination of this Agreement.
            </dd>
            <dd>
              <span class="term">13.3</span>During the period under notice, both
              the Parties shall be bound to perform their obligations incurred
              under this Agreement, and this sub-clause shall survive the
              termination of this Agreement.{" "}
            </dd>

            <dt>
              <span class="terms">14.</span>GOVERNING LAW AND DISPUTE
              RESOLUTION:
            </dt>
            <dd>
              <span class="term">14.1 </span>This Agreement shall be construed
              and enforced in accordance with the laws of
              [STATE/PROVINCE]without regard to the Company or the
              Website/Portal of its conflict of law provisions or the User’s
              state or country of residence.
            </dd>
            <dd>
              <span class="term">14.2</span>The Usersubmits to the exclusive
              jurisdiction of the courts of [STATE/PROVINCE] for the enforcement
              of this Agreement or any arbitration award or decision arising
              from this Agreement.
            </dd>
            <dd>
              <span class="term">14.3</span>If there is a dispute between the
              User and other Users, the User understands and agrees that the
              Company is under no obligation with respect thereto, and the User,
              to the fullest extent permitted by law, hereby releases the
              Company and its affiliates, and each of their respective officers,
              directors, employees, service providers, affiliates, agents, and
              successors from, and agrees to indemnify each of the foregoing for
              any losses incurred in connection with any and all claims, demands
              and damages (actual and consequential) of every kind or nature,
              known or unknown, suspected and unsuspected, disclosed and
              undisclosed, arising out of or in any way related to such
              disputes.
            </dd>
            <dd>
              <span class="term">14.4</span>14.4. The User agrees that in case
              it is unable to resolve its disputes with other Users of the
              Website/Portal,w.r.t any conflict related to the services and
              offerings provided by the company, then the Company has the right
              to remove the User from the Website and terminate this Agreement.
            </dd>

            <dt>
              <span class="terms">15.</span>DISCLAIMER
            </dt>
            <dd>
              <span class="term">15.1 </span>To the fullest extent permitted by
              law, the Company and its affiliates, and each of its respective
              officers, directors, members, employees, and agents disclaim all
              warranties, express or implied, in connection with this Agreement,
              the Website and any use thereof, including, without limitation,
              the implied warranties of merchantability, fitness for a
              particular purpose and non-infringement. The Company makes no
              warranties or representations about the accuracy or completeness
              of the Website's Content or the Content of any other Websites
              linked to the Website, and assumes no liability or responsibility
              for any (a) errors, mistakes, or inaccuracies of Content and
              materials, (b) personal injury or property damage, of any nature
              whatsoever, resulting from the User's access to and use of the
              Website, (c) any unauthorized access to or use of the Company's
              servers and/or any and all personal information and/or financial
              information stored therein, (d) any interruption or cessation of
              transmission to or from the Website, (e) any bugs, viruses, trojan
              horses, or the like which may be transmitted to or through the
              Website by any third party, and/or (f) any errors or omissions in
              any Content and materials or for any loss or damage of any kind
              incurred as a result of the use of any Content posted,
              transmitted, or otherwise made available via the Website/Portal.
              The Company does not guarantee the privacy, security or
              authenticity of any information so transmitted over or stored in
              any system connected to the internet or mobile devices.
            </dd>

            <dt>
              <span class="terms">16.</span>NOTICES
            </dt>
            <dd>
              <span class="term">16.1 </span>Except as explicitly stated
              otherwise, any notices given to the Company shall be given by
              email to [contact@evalmybrand.com] or at an address [10th floor,
              SanaliSpazio, Madhapur, Hyderabad - 500081 Telangana, INDIA.]. Any
              notices given to the User shall be to the email address provided
              by the User to the Company/Portal at the time of listing (or as
              such information may be updated via the Website/Portal by the User
              from time to time) or at the mailing address provided by the User
              to the Company.
            </dd>
            <dd>
              <span class="term">16.2 </span>Any notice, demand, or request with
              respect to this Agreement shall be in writing and shall be
              effective only if it is delivered by email, personal service, by
              air courier with receipt of delivery, or mailed, certified mail,
              return receipt requested, postage prepaid, to the address set
              forth above. Such communications shall be effective when they are
              received by the addressee, but if sent by certified mail in the
              manner set forth above, they shall be effective seven (7) days
              after being deposited in the mail. Any Party may change its
              address for such communications by giving notice to the other
              Party in conformity with this section.
            </dd>

            <dt>
              <span class="terms">17.</span>AMENDMENT
            </dt>
            <dd>
              <span class="term">17.1 </span>The Company may at any time at its
              sole discretion modify this Agreement from time to time, and any
              such changes will (i) be reflected on the Website, (ii) be
              effective thirty (30) calendar days after being so posted on the
              Website, (iii) not apply retroactively, and (iv) not apply to any
              disputes arising prior to the effective date of such change. The
              Company shall also post the amended Agreement to the address of
              the User. The User agrees to be bound to any such changes and
              understands the importance of regularly reviewing this Agreement
              as updated on the Website to keep the User’s listing and contact
              information current.
            </dd>
            <dd>
              <span class="term">17.2 </span>Notwithstanding anything to the
              contrary herein, the Company reserves the right to, at any time
              and from time to time, change, alter, modify, or discontinue the
              Website (or any part thereof) with or without notice. The User
              agrees that the Company shall have no liability to the User or to
              any third party for any change, alteration, modification,
              suspension, discontinuance, or amendment of the Company’s Website.
            </dd>

            <dt>
              <span class="terms">18.</span>INTELLECTUAL PROPERTY RIGHTS
            </dt>
            <dd>
              <span class="term">18.1 </span>Supplier or its licensors owns all
              intellectual property rights in the Services and the Supplier
              Data. Customer owns all intellectual property rights in the
              Customer Data. Other than as set out elsewhere in this Agreement,
              Customer is not granted any rights to Supplier’s intellectual
              property rights.
            </dd>

            <dt>
              <span class="terms">19.</span>FORCE MAJEURE
            </dt>
            <dd>
              <span class="term">19.1 </span>Except with regard to payment
              obligations, either Party shall be excused from delays in
              performing or from failing to perform its obligations under this
              contract to the extent the delays or failures resulting from
              causes beyond the reasonable control of the Party, including, but
              not limited to: failures or default of third-party software,
              Users, or products; acts of God or of a public enemy; foreign
              governmental actions; strikes; communications, network connection,
              or utility interruption or failure; fire; flood; epidemic; or
              freight embargoes.
            </dd>

            <dt>
              <span class="terms">20.</span>CONTACT US:
            </dt>
            <dd>
              <span class="term">20.1 </span>For any further clarification of
              Our Terms and Conditions, please write to Us at
              [contact@evalmybrand.com].
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
}
export default Terms;
