import React, { useEffect, useRef } from "react";
// import img1 from "./images/slide-one.png";
// import img2 from "./images/slide-two.png";
// import img3 from "./images/slide-three.png";
// import Carousel from "react-bootstrap/Carousel";
// import { Typewriter } from "react-simple-typewriter";
import { useState } from "react";
// import TextTransition, { presets } from "react-text-transition";
// import { Helmet } from "react-helmet";
// import { HashLink as Link } from "react-router-hash-link";
import axios from "axios";
import baseurl from "./settings.json";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import ReactPlayer from "react-player";

import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import Button from 'react-bootstrap/Button';


//class home extends React.Component {

function Home() {
  const [texts] = useState([]);
  const [testim, settestimonials] = useState([]);
  const [blogs, setblogs] = useState([]);
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [stext, setstext] = useState({
    sttext: "",
    slink: "",
    slinkbbuttonname: "",
  });
  const [banner, setbanner] = useState({
    sbt: "",
    transtext: "",
    othertext: "",
  });
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  }; 
  useEffect(() => {
    //get banner details
    axios
      .get(baseurl.baseurl + "/frontend_getbannerslist", {})
      .then((response) => {
        if (response.data.status === "Fail") {
        } else {
          setbanner({
            sbt: response.data.results[0].sbt,
            // transtext: response.data.results[0].transtext,
            othertext: response.data.results[0].othertext,
          });

          // settexts(banner.transtext.split(","));
        }
      });
    //end getting banner details

    //getting testimonials
    axios
      .get(baseurl.baseurl + "/frontend_gettestimonials", {})
      .then((response) => {
        if (response.data.status === "Fail") {
        } else {
          settestimonials(response.data.results);
        }
      });
    //end getting testimonials

    //getting blogs
    axios.get(baseurl.baseurl + "/frontend_getblogs", {}).then((response) => {
      if (response.data.status === "Fail") {
      } else {
        setblogs(response.data.results);
      }
    });

    //getting sticky text
    axios.get(baseurl.baseurl + "/frontend_stickytext", {}).then((response) => {
      if (response.data.status === "Fail") {
      } else {
        setstext({
          sttext: response.data.results[0].stickytext,
          slink: response.data.results[0].link,
          slinkbbuttonname: response.data.results[0].linkbuttonname,
        });
      }
    });
  }, [texts.length === 0]);
  const myRef = useRef(null);
  const tref = useRef(null);
  const cref = useRef(null);

  const gotocustomers = () => {
    if (window.innerWidth > 767) {
      window.scrollTo({
        top: cref.current.offsetTop - 150,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: cref.current.offsetTop - 95,
        behavior: "smooth",
      });
    }
  }; 
  const executeScroll = () => {
    if (window.innerWidth > 767) {
      window.scrollTo({
        top: tref.current.offsetTop - 200,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: tref.current.offsetTop - 100,
        behavior: "smooth",
      });
    }
  };
  const [show, setShow] = useState(true);
  const [index, setIndex] = React.useState(0);
  const gotoproducts = () => {
    if (window.innerWidth > 767) {
      window.scrollTo({
        top: 1100,
        behavior: "smooth",
      });
      console.log("system " + window.innerWidth);
    } else {
      window.scrollTo({
        top: myRef.current.offsetTop - 100,
        behavior: "smooth",
      });
      console.log("Mobile " + window.innerWidth);
    }
  };
  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      5500 // every 3 seconds
    );
    let ul = window.location.href;
    if (ul.includes("#products")) {
      if (window.innerWidth > 767) {
        window.scrollTo({
          top: myRef.current.offsetTop - 30,
          behavior: "smooth",
        });
        console.log("system " + window.innerWidth);
      } else {
        window.scrollTo({
          top: myRef.current.offsetTop - 100,
          behavior: "smooth",
        });
        console.log("Mobile " + window.innerWidth);
      }
    } 
    else if (ul.includes("#favourite")) {
      window.scrollTo({
        top: tref.current.offsetTop - 115,
        behavior: "smooth",
      });
    }

    return () => clearTimeout(intervalId);
  }, []);
  return (
    <div>
      {/* <div className="container-fluid top-header d-none d-md-flex "> */}
      <div className="container-fluid top-header d-none d-md-flex " style={{ background: '#000' }}>
        <div className="head-cont d-flex justify-content-between w-100">
          <div className="header-logo">
            <a href="/">
              <img src="pages/images/evalmybrand.png" alt="evalmyBRANDlogo" />
            </a>
          </div>
          <div className="header-right-side d-flex">
            <a
              href="https://portal.evalmybrand.com"
              className="mt-2 nav-link active"
              target="_blank"
              rel="noreferrer"
            >
              Login
            </a> 
          </div>
        </div>
      </div>
      {/* <div className="row"> */}
      <div className="row" style={{ background: "#000" }}>
        {/* <div className="wrapper">
  <video className="videoInsert"  src="pages/images/homebg.mp4" type="video/mp4" controls={false} autoPlay={true} muted={true} loop={true} style={{marginTop:"4.5rem"}}>
 </video>
</div> */}
        <div className="top-section col-md-6">
          <div
            style={{
              position: "relative",
              height: "100vh",
              backgroundSize: "cover",
            }}
          >

            {/* <div style={{ position: "absolute", width: "100%", zIndex: 0 }}>
            <Carousel controls={false} indicators={false}>
              <Carousel.Item inteval={100}>
                <img
                  className="d-block w-100 slider-image"
                  src={img1}
                  alt="First slide"
                />
                <Carousel.Caption
                  style={{ top: "30%", textAlign: "left" }}
                ></Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item inteval={100}>
                <img
                  className="d-block w-100 slider-image"
                  src={img2}
                  alt="Second slide"
                />

                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item inteval={100}>
                <img
                  className="d-block w-100 slider-image"
                  src={img3}
                  alt="Third slide"
                />

                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div> */}
            <div
              style={{
                position: "absolute",
                // width: "100vw",
                height: "100vh",
                backgroundColor: "#fff",
                top: 0,
                left: 0,
                opacity: 0.3,
              }}
            ></div>
            <div
              className="slider-content p-3 p-lg-5"
              style={{
                top: "50%",
                transform: "translateY(-50%)",
                // width: "100vw",
                position: "absolute",
                color: "#000",
                zIndex: 9,
              }}
            >
              <h1 className="headBanner">{[banner.sbt]}
                {/* <Typewriter
                loop
                cursor
                cursorStyle="_"
                typeSpeed={100}
                deleteSpeed={100}
                delaySpeed={1500}
                fontWeight={"bold"}
                words={[banner.sbt]}
                style={{ color: "#FF16E4" }}
              ></Typewriter>{" "} */}
              </h1>

              <p className="d-flex align-items-top mb-0">
                {/* <TextTransition
                  style={{ color: "#FF16E4", display: "inline" }}
                  springConfig={presets.wobbly}
                >
                  {texts[index % texts.length]}
                </TextTransition> */}
                <span className="top">
                  {banner.othertext.split("|")[0]}
                </span>
              </p>
              {/* <p className="top"> {banner.othertext.split("|")[1]}</p> */}

              <div className=" brand-presence-btns d-flex align-items-center mt-4 homeBrandBtns">
                <a href="/getstarted">
                  {" "}
                  <button className="btn btn-primary mx-2 gtBTN">
                    Get Started
                    <svg
                      className="ms-2 bi bi-arrow-right"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </button>
                </a>
                {/* <a
                href="https://www.youtube.com/watch?v=1QYXW5Ex-4A/"
                target="_blank"
              > */}
                <button className="mx-2 getStartedBtn gtBTN" onClick={onOpenModal}>
                  <svg
                    className="me-2 bi bi-play-circle"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#fff"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                  </svg>
                  How it Works ?
                </button>
                {/* </a> */}
              </div>
              <div className="social-imp rightToLeft">
                  <a
                    href="https://www.facebook.com/people/evalmyBRAND/100086047461629/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="pages/images/homeicon1.png"
                      alt="facebooklogo"
                      className="social homesocial"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/evalmybrand/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="pages/images/homeicon2.png"
                      alt="linkedinlogo"
                      className="social homesocial"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/evalmybrand/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="pages/images/homeicon3.png"
                      alt="instagramlogo"
                      className="social homesocial"
                    />
                  </a>
                  <a href=" https://twitter.com/evalmyBrand" target="_blank">
                    <img
                      src="pages/images/homeicon4.png"
                      alt="twitterlogo"
                      className="social homesocial"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCOs9P0nxGKD8uCDWWHRxgkw/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="pages/images/homeicon5.png"
                      alt="youtubelogo"
                      className="social homesocial"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/evalmybrand/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="pages/images/homeicon6.png"
                      alt="linkedinlogo"
                      className="social homesocial"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/evalmybrand/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="pages/images/homeicon7.png"
                      alt="instagramlogo"
                      className="social homesocial"
                    />
                  </a>
                  <a href=" https://twitter.com/evalmyBrand" target="_blank">
                    <img
                      src="pages/images/homeicon8.png"
                      alt="twitterlogo"
                      className="social homesocial"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCOs9P0nxGKD8uCDWWHRxgkw/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="pages/images/homeicon9.png"
                      alt="youtubelogo"
                      className="social homesocial"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCOs9P0nxGKD8uCDWWHRxgkw/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="pages/images/homeicon10.png"
                      alt="youtubelogo"
                      className="social homesocial"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCOs9P0nxGKD8uCDWWHRxgkw/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="pages/images/homeicon11.png"
                      alt="youtubelogo"
                      className="social homesocial"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCOs9P0nxGKD8uCDWWHRxgkw/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="pages/images/homeicon12.png"
                      alt="youtubelogo"
                      className="social homesocial"
                    />
                  </a>
                </div>
            </div>
          </div>
          {/* <div className='footerLogos'>
            <img src='pages/images/taim.png' />
            <img src='pages/images/microsoftstartup.png' />
          </div> */}
          <div className="mouse-ani">
            <img src="pages/images/noun-mouse.svg" alt="evalmyBRANDmouseicon" />
          </div>
        </div>
        {/* <div className="col-md-6" style={{ height: "100vh", marginTop: '-5rem', padding: '0' }}> */}
        <div className="col-md-6 homePageRightSide">
          <div className="social-imp homePageRightSideIcons">
            <a
              href="https://www.facebook.com/people/evalmyBRAND/100086047461629/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="pages/images/homeicon1.png"
                alt="facebooklogo"
                className="social homesocial"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/evalmybrand/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="pages/images/homeicon2.png"
                alt="linkedinlogo"
                className="social homesocial"
              />
            </a>
            <a
              href="https://www.instagram.com/evalmybrand/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="pages/images/homeicon3.png"
                alt="instagramlogo"
                className="social homesocial"
              />
            </a>
            <a href=" https://twitter.com/evalmyBrand" target="_blank">
              <img
                src="pages/images/homeicon4.png"
                alt="twitterlogo"
                className="social homesocial"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCOs9P0nxGKD8uCDWWHRxgkw/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img
                src="pages/images/homeicon5.png"
                alt="youtubelogo"
                className="social homesocial"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/evalmybrand/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="pages/images/homeicon6.png"
                alt="linkedinlogo"
                className="social homesocial"
              />
            </a>
            <a
              href="https://www.instagram.com/evalmybrand/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="pages/images/homeicon7.png"
                alt="instagramlogo"
                className="social homesocial"
              />
            </a>
            <a href=" https://twitter.com/evalmyBrand" target="_blank">
              <img
                src="pages/images/homeicon8.png"
                alt="twitterlogo"
                className="social homesocial"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCOs9P0nxGKD8uCDWWHRxgkw/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img
                src="pages/images/homeicon9.png"
                alt="youtubelogo"
                className="social homesocial"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCOs9P0nxGKD8uCDWWHRxgkw/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img
                src="pages/images/homeicon10.png"
                alt="youtubelogo"
                className="social homesocial"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCOs9P0nxGKD8uCDWWHRxgkw/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img
                src="pages/images/homeicon11.png"
                alt="youtubelogo"
                className="social homesocial"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCOs9P0nxGKD8uCDWWHRxgkw/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img
                src="pages/images/homeicon12.png"
                alt="youtubelogo"
                className="social homesocial"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="row" style={{ display: "none" }}>
        <div className="col-md-6">
          <div
            style={{
              position: "relative",
              height: "94vh",
              backgroundSize: "cover",
            }}
          >
            <p style={{ textAlign: "center", fontSize: '20px', fontWeight: "600", marginTop: "2rem", marginBottom: "-2rem" }}>A Complete CXM Suite</p>
            <img src="pages/images/brands.jpg" style={{ width: '100%', padding: "50px" }} alt='brand' />

          </div>
        </div>
        <div className="col-md-6" style={{ height: "100vh", background: '#000', marginTop: '0rem', padding: '0' }}>
          {<video src='pages/images/video.mp4' width="750" height="500" controls={false} autoPlay={true} loop={true} muted={true} style={{ marginTop: "4.5rem" }}>
          </video>}
        </div>
      </div>
      <div className="home-page-section positon-sticky" id="header">
        <div style={{ display: show ? "block" : "none" }}>
          <div className="strip">
            <div className="new">
              <button type="button" className="btn-2 pe-none">
                New
              </button>
            </div>
            <p className="evalmy">{stext.sttext}</p>
            <a href={stext.slink} target="_blank" rel="noreferrer">
              <p className="explore">
                {stext.slinkbbuttonname}{" "}
                <svg
                  className="ms-2 bi bi-arrow-right"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                  />
                </svg>
              </p>
            </a>
            <span id="close" onClick={() => setShow((s) => !s)}> X
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-x-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
              </svg> */}
            </span>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg" id="navbar">
          <div className="container-fluid">
            <a className="navbar-brand" href="https://evalmybrand.com/">
              <img
                src="pages/images/evalmybrand-Logo.png"
                alt="evalmybrandlogo"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {/* <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="https://evalmybrand.com/"
                  >
                    Home
                  </a>
                </li> */}
                <li className="nav-item">
                  <a className="nav-link" href="/about">
                    About Us
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#products"
                    onClick={gotoproducts}
                  >
                    Features
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link"
                    href="/pricing"
                  // onClick={gotopricing}
                  >
                    Pricing
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://blog.evalmybrand.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    News & Blog
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/getstarted">
                    Contact Us
                  </a>
                </li>
              </ul>
              <div className="nav-btns d-flex hide">
                <a
                  href="https://portal.evalmybrand.com"
                  className="mt-1 nav-link active"
                  target="_blank"
                  rel="noreferrer"
                >
                  Login
                </a>
                {/* <a href="/sales">
                  <button className="btn btn-primary">Request Demo</button>
                </a> */}
              </div>
            </div>
          </div>
        </nav>
      </div>

      {/* <div className="brand-presense">
        <div className="leverage text-center ">
          <button className="btn">
            Leverage your brand presence with evalmyBRAND
          </button>
          <h2>
            Being{" "}
            <span style={{ fontWeight: 300 }}>
              <i>just</i>
            </span>{" "}
            social is a monologue.
            <br />
            Don't be{" "}
            <span style={{ fontWeight: 300 }}>
              <i>just!</i>
            </span>
          </h2>
          <p>
            Communicate through your brand to it's fullest ability with
            evalmyBRAND. We understand, listen and monitor your brand insights
            better.{" "}
          </p>
          <div className=" brand-presence-btns">
            <a href="/sales">
              <p className="get">
                Get Started
                <svg
                  className="ms-2 bi bi-arrow-right"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                  />
                </svg>
              </p>
            </a>
            <p onClick={onOpenModal} style={{ cursor: "pointer" }}>
              <svg
                className="me-2 bi bi-play-circle"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#7B41D8"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
              </svg>
              How it Works
            </p>
          </div>
        </div>
        <div className="social-icons">
          <a href="#" className="deg0">
            <img
              src="pages/images/app_store_ios-logo.svg"
              alt="appstoreioslogo"
            />
          </a>
          <a href="#" className="deg45">
            <img src="pages/images/twitter-logo.svg" alt="twitterlogo" />
          </a>
          <a href="#" className="deg90">
            <img src="pages/images/insta-logo.svg" alt="instalogo" />
          </a>
          <a href="#" className="deg135">
            <img src="pages/images/linked-in-logo.svg" alt="linkedinlogo" />
          </a>
          <a href="#" className="deg180">
            <img src="pages/images/facebook-logo.svg" alt="facebooklogo" />
          </a>
          <a href="#" className="deg225">
            <img src="pages/images/youtube-logo.png" alt="youtubelogo" />
          </a>
          <a href="#" className="deg315">
            <img src="pages/images/playstore-logo.svg" alt="playstorelogo" />
          </a>
        </div>
      </div> */}

      <div className="brands" ref={tref}>
        <div className="container">
          <div className="circle"></div>
          <div className="rectangle-2"></div>
        </div>
        <h3 className="text-center">Cherished by Foremost</h3>
        <p>
          We are proud to serve startups to mid-cap industries to large
          businesses. Our partnerships have been growing successfully and
          encouraging us to serve even better!{" "}
        </p>

        {/* <img src="pages/images/left-edge-design.svg" alt="" className="leftedge" /> */}
        {/* <img
          src="pages/images/right-edge-design.svg"
          alt="evalmybrandrightedgeicon"
          className="rightedge"
        /> */}
        <div>
          <div className="text-center">
            <div className="row g-2 g-lg-2">
              <div className="brandsInfo">
                {/* <h5 className="text-center">Cherished by Foremost</h5> */}

                <MultiCarousel className="brandsCarousel"
                  swipeable={false}
                  draggable={false}
                  showDots={false}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay={true}
                  // keyBoardControl={true}
                  // customTransition="all .5"
                  transitionDuration={3000}
                  containerClass="carousel-container"
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                >
                  <div className="brandBox"><img src="pages/images/Welspun.jpg" alt="" className="brandImg" />
                    <p className="brandTitle"><span>Welspun Group</span></p>
                    <p className="brandSubTitle">One of India's fastest-growing global conglomerates in multiple industries</p> </div>
                  <div className="brandBox"><img src="pages/images/ALMulla Media.jpg" alt="" className="brandImg" />
                    <p className="brandTitle"><span>Almulla Media</span></p>
                    <p className="brandSubTitle">Renowned Marketing Agency & SMS Providers in Kuwait</p> </div>
                  <div className="brandBox"><img src="pages/images/Bud-e.jpg" alt="" className="brandImg" />
                    <p className="brandTitle"><span>Bud-e</span></p>
                    <p className="brandSubTitle">The next-gen EV Rental Company with Sustainability at its core</p> </div>
                  {/* <div className="brandBox"><img src="pages/images/Driven.jpg" alt="" className="brandImg" />
                    <p className="brandTitle"><span>Driven</span></p>
                    <p className="brandSubTitle"><br></br><br></br></p> </div> */}
                  <div className="brandBox"><img src="pages/images/AirDriven.jpg" alt="" className="brandImg" />
                    <p className="brandTitle"><span>AirDriven Aviation</span></p>
                    <p className="brandSubTitle">The new age luxury private charter rental global company</p> </div>
                  <div className="brandBox"><img src="pages/images/HYDFC.jpg" alt="" className="brandImg" />
                    <p className="brandTitle"><span>Hyd Fc</span></p>
                    <p className="brandSubTitle">The champion football club representing the city of Nizams</p> </div>
                </MultiCarousel>

              </div>
              <a href="#testmonials" onClick={gotocustomers}>
                {" "}
                <p className="text-center mt-5 mb-5 read mobileCutomerTitle">
                  Read our customer stories
                  <svg
                    className="ms-2 bi bi-arrow-right"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                </p>
              </a>

            </div>
          </div>
        </div>
        {/* <div className="desktop-version ">
          <div className="container text-center">
            <div className="row  row-cols-lg-5  g-2 g-lg-2">
              <div className="col d-flex align-items-center">
                <img
                  src="pages/images/aimlytics_logo.png"
                  alt="aimlyticslogo"
                  className="brand-img"
                />
              </div>
              <div className="col">
                <img
                  src="pages/images/Airdriven-logo.svg"
                  alt="airdrivenlogo"
                  className="brand-img"
                />
              </div>
              <div className="col">
                <div className="pg">
                  <img
                    src="pages/images/bud-e.in.svg"
                    alt="budelogo"
                    className="brand-img"
                  />
                </div>
              </div>
              <div className="col">
                <div className="pg">
                  <img
                    src="pages/images/driven-logo.svg"
                    alt="drivenlogo"
                    className="brand-img"
                  />
                </div>
              </div>
              <div className="col">
                <div className="pg">
                  <img
                    src="pages/images/Eclatprime-logo.svg"
                    alt="eclatprimelogo"
                    className="brand-img"
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-5 brand-img">
              <div className="col"></div>
              <div className="col">
                <img
                  src="pages/images/Hydfc-logo.svg"
                  alt="hydfclogo"
                  className="brand-img"
                />
              </div>
              <div className="col">
                <img
                  src="pages/images/Dextara-logo.svg"
                  alt="dextaralogo"
                  className="brand-img"
                />
              </div>
              <div className="col">
                <img
                  src="pages/images/qualifyde-logo.svg"
                  alt="qualifydelogo"
                  className="brand-img"
                />
              </div>
              <div className="col"></div>
              <a href="#testmonials" onClick={gotocustomers}>
                {" "}
                <p className="text-center mt-5 mb-5 read">
                  Read Our Customer Stories
                  <svg
                    className="ms-4 bi bi-arrow-right"
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                </p>
              </a>
            </div>
          </div>
        </div> */}
        {/* <div className="mobile-version">
          <div className="container text-center">
            <div className="row justify-content-center">
              <div className="col-3">
                <div className="for-mob">
                  <img
                    src="pages/images/aimlytics_logo.png"
                    alt="aimlyticslogo"
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="for-mob">
                  <img
                    src="pages/images/Airdriven-logo.svg"
                    alt="airdrivenlogo"
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="for-mob">
                  <img src="pages/images/bud-e.in.svg" alt="budelogo" />
                </div>
              </div>
              <div className="col-3">
                <div className="for-mob">
                  <img src="pages/images/driven-logo.svg" alt="drivenlogo" />
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-3">
              <div className="col-4">
                <div className="for-mob">
                  <img
                    src="pages/images/Eclatprime-logo.svg"
                    alt="eclatprimelogo"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="for-mob">
                  <img src="pages/images/Hydfc-logo.svg" alt="hydfclogo" />
                </div>
              </div>
              <div className="col-4">
                <div className="for-mob">
                  <img src="pages/images/Dextara-logo.svg" alt="dextaralogo" />
                </div>
              </div>
            </div>
            <a href="#testmonials" onClick={gotocustomers}>
              <p className="text-center mt-5 mb-5 read">
                Read Our Customer Stories
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  fill="currentColor"
                  className="bi bi-arrow-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                  />
                </svg>
              </p>
            </a>
          </div>
        </div> */}
      </div>

      <div className="products mt-5 mobileProducts" id="product" ref={myRef}>
        <div className="container">
          <div className="circle">
            <img
              src="pages/images/small-circle.png"
              alt="evalmyBRANDcircleicon"
            />
          </div>
          <div className="rectangle-2">
            <img
              src="pages/images/rectangle-design.png"
              alt="evalmyBRANDrectangleicon"
            />
          </div>
          <h5 className="text-center">Features</h5>
          <h2 className="text-center pt-2 mb-2">
            A One-Stop Solution For All Your Social Listening Needs.
          </h2>
          <p className="platform text-center mb-3">
            Utilise evalmyBRAND's AI-powered platform to turn unstructured data
            into useful insights and get a holistic view of what customers are
            saying about your brand.
          </p>
          <div className="detailed-products">
              <div className="showTrends">
            <div className="row">
              {/* <div className="rectangle">
                <img
                  src="pages/images/rectangle-design.png"
                  alt="evalmyBRANDrectangleicon"
                />
              </div> */}
              
              <div className="col-md-4 col-sm-2">
                <div className="fetureModel">
                  <a className="product" href="/features">
                    <img
                      src="pages/images/insight.png"
                      alt="evalmycasemanagement"
                      className="prod-img"
                    />
                    <div className="productInfo p-1">
                      <p className="productInfoTitle mb-0">Insights & Analytics</p>
                      <p className="productInfoDescription mb-0"><p className="productInfoTitlenew mb-0">Insights & Analytics</p>
                        Detailed Analysis of social media trends, engagement & more. . .</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-2">
                <div className="fetureModel">
                  <a className="product" href="/features">
                    <img
                      src="pages/images/sociallisten.png"
                      alt="evalmyinsightanalysis"
                      className="prod-img"
                    />
                    <div className="productInfo p-1">
                      <p className="productInfoTitle mb-0">Social Listening</p>
                      <p className="productInfoDescription mb-0"><p className="productInfoTitlenew mb-0">Social Listening</p>
                        Track customer feedback, competitor activity, and industry trends</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-2">
                <div className="fetureModel">
                  <a className="product" href="/features">
                    <img
                      src="pages/images/socialmoniter.png"
                      alt="evalmyengagementanalysis"
                      className="prod-img"
                    />
                    <div className="productInfo p-1">
                      <p className="productInfoTitle mb-0">Social Media Monitoring</p>
                      <p className="productInfoDescription mb-0"><p className="productInfoTitlenew mb-0">Social Media Monitoring</p>
                        Track brand mentions and monitor sentiment gaps ...</p>
                    </div>
                  </a>
                </div>
              </div> 
              <div className="col-md-4 col-sm-2">
                <div className="fetureModel">
                  <a className="product" href="/features">
                    <img
                      src="pages/images/appa.png"
                      alt="evalmysentimentanalysis"
                      className="prod-img"
                    />
                    <div className="productInfo p-1">
                      <p className="productInfoTitle mb-0">App Analysis</p>
                      <p className="productInfoDescription mb-0"><p className="productInfoTitlenew mb-0">App Analysis</p>
                        Know users engagement on mobile apps and identify scope of improvement</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-2">
                <div className="fetureModel">
                  <a className="product" href="/features">
                    <img
                      src="pages/images/comapin.png"
                      alt="evalmycompetitoranalysis"
                      className="prod-img"
                    />
                    <div className="productInfo p-1">
                      <p className="productInfoTitle mb-0">Campaign Analyzer</p>
                      <p className="productInfoDescription mb-0"><p className="productInfoTitlenew mb-0">Campaign Analyzer</p>
                        Track social media campaigns and get competitive predictions to success</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-2">
                <div className="fetureModel">
                  <a className="product" href="/features">
                    <img
                      src="pages/images/casemangement.png"
                      alt="evalmyBRANDgoal"
                      className="prod-img"
                    />
                    <div className="productInfo p-1">
                      <p className="productInfoTitle mb-0">Case Management</p>
                      <p className="productInfoDescription mb-0"><p className="productInfoTitlenew mb-0">Case Management</p>
                        Effectively manage and reduce the risks of virality on social media.</p>
                    </div>
                  </a>
                </div>
              </div>
              </div>
            </div>

              <div className="hideTrends">
              <MultiCarousel className="brandsCarousel featureCarousel"
                  swipeable={false}
                  draggable={false}
                  showDots={true}
                  arrows={false}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay={true}
                  // keyBoardControl={true}
                  // customTransition="all .5"
                  transitionDuration={3000}
                  containerClass="carousel-container"
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                >
                    <div className="col-md-4 col-sm-2">
                <div className="fetureModel">
                  <a className="product" href="/features">
                    <img
                      src="pages/images/insight.png"
                      alt="evalmycasemanagement"
                      className="prod-img"
                    />
                    <div className="productInfo p-1">
                      <p className="productInfoTitle mb-0">Insights & Analytics</p>
                      <p className="productInfoDescription mb-0"><p className="productInfoTitlenew mb-0">Insights & Analytics</p>
                        Detailed Analysis of social media trends, engagement & more. . .</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-2">
                <div className="fetureModel">
                  <a className="product" href="/features">
                    <img
                      src="pages/images/sociallisten.png"
                      alt="evalmyinsightanalysis"
                      className="prod-img"
                    />
                    <div className="productInfo p-1">
                      <p className="productInfoTitle mb-0">Social Listening</p>
                      <p className="productInfoDescription mb-0"><p className="productInfoTitlenew mb-0">Social Listening</p>
                        Track customer feedback, competitor activity, and industry trends</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-2">
                <div className="fetureModel">
                  <a className="product" href="/features">
                    <img
                      src="pages/images/socialmoniter.png"
                      alt="evalmyengagementanalysis"
                      className="prod-img"
                    />
                    <div className="productInfo p-1">
                      <p className="productInfoTitle mb-0">Social Media Monitoring</p>
                      <p className="productInfoDescription mb-0"><p className="productInfoTitlenew mb-0">Social Media Monitoring</p>
                        Track brand mentions and monitor sentiment gaps ...</p>
                    </div>
                  </a>
                </div>
              </div> 
              <div className="col-md-4 col-sm-2">
                <div className="fetureModel">
                  <a className="product" href="/features">
                    <img
                      src="pages/images/appa.png"
                      alt="evalmysentimentanalysis"
                      className="prod-img"
                    />
                    <div className="productInfo p-1">
                      <p className="productInfoTitle mb-0">App Analysis</p>
                      <p className="productInfoDescription mb-0"><p className="productInfoTitlenew mb-0">App Analysis</p>
                        Know users engagement on mobile apps and identify scope of improvement</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-2">
                <div className="fetureModel">
                  <a className="product" href="/features">
                    <img
                      src="pages/images/comapin.png"
                      alt="evalmycompetitoranalysis"
                      className="prod-img"
                    />
                    <div className="productInfo p-1">
                      <p className="productInfoTitle mb-0">Campaign Analyzer</p>
                      <p className="productInfoDescription mb-0"><p className="productInfoTitlenew mb-0">Campaign Analyzer</p>
                        Track social media campaigns and get competitive predictions to success</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-2">
                <div className="fetureModel">
                  <a className="product" href="/features">
                    <img
                      src="pages/images/casemangement.png"
                      alt="evalmyBRANDgoal"
                      className="prod-img"
                    />
                    <div className="productInfo p-1">
                      <p className="productInfoTitle mb-0">Case Management</p>
                      <p className="productInfoDescription mb-0"><p className="productInfoTitlenew mb-0">Case Management</p>
                        Effectively manage and reduce the risks of virality on social media.</p>
                    </div>
                  </a>
                </div>
              </div>
                </MultiCarousel>
                </div>
              <div className="design-2">
                <img src="pages/images/design-2.png" alt="evalmydesignlogo" />
              </div>
          </div>
        </div>
      </div>

      {/* <div className="scaling-up">
        <div className="container">
          <h3 className="text-center mt-5 mb-4">
            Interested in Scaling Up Your Business With Us
          </h3>
          <p className="drop text-center mt-4 mb-4">
            Drop us an email or talk to us to learn more about what evalmyBRAND
            offers to help you grow in your social space. You can try our quick
            demo or watch our{" "}
           
            <span style={{ color: "white", cursor: "pointer" }} onClick={onOpenModal}> how it works?</span>
            
            {" "}
            to know us better.{" "}
          </p>
          <div className="d-flex justify-content-center">
            <p className="trial me-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                className="bi bi-check-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
              </svg>{" "}
              14-days-trial
            </p>
            <p className="trial me-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                className="bi bi-check-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
              </svg>
              No credit card required
            </p>
            <p className="trial me-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                className="bi bi-check-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
              </svg>
              Cancel anytime
            </p>
          </div>
          <div className="options">
            <a href="/sales">
              <button type="button" className="btn btn-success btn-lg">
                Get Free trial
              </button>
            </a>

            <a href="/sales">
              <button type="button" className="btn btn-lg">
                Talk to Sales
              </button>
            </a>
          </div>
        </div>
      </div> */}

      <div className="pricing-table" id="price">
        <div id="pricing-desktop">
          <div className="container text-center py-5">
            <h4 className="display-4 mb-4">What We offer</h4>
            <h3 className="lead mb-4">
              Choose the Right plan For Your Business
            </h3>
            <p>
              We have plans to accommodate businesses of all sizes. Choose the
              right plan that best suits the social needs of your business.
            </p>
            <div className="row justify-content-center g-3">
              <div className="col-lg-3 col-md-6">
                <div className="card h-100 mx-0">
                  <div className="card-header">
                    <h4>Basic</h4>
                  </div>
                  <div className="card-body ">
                    <div className="rightplan">
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.png"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span>1 User Access</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.png"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span>Updated Data Monthly</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.png"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span>Reports</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.svg"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span style={{ color: "#9CA3C5" }}>
                          Competitive Analysis
                        </span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.svg"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span style={{ color: "#9CA3C5" }}>Mobile App</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.svg"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span style={{ color: "#9CA3C5" }}>Email Digest</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.svg"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span style={{ color: "#9CA3C5" }}>
                          24/7 Customer Support
                        </span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.svg"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span style={{ color: "#9CA3C5" }}>
                          Unlimited Product Updates
                        </span>
                      </div>
                    </div>
                    <button className="btn btn-success mt-5">
                      Get Started
                    </button>
                    <h6>Start Free Trail</h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card h-100 mx-0">
                  <div className="card-header">
                    <h4>Standard</h4>
                  </div>
                  <div className="card-body">
                    <div className="rightplan">
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.png"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span>1 User Access</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.png"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span>Updated Data Monthly</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.png"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span>Reports</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.png"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span>Competitive Analysis</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.svg"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span style={{ color: "#9CA3C5" }}>Mobile App</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.svg"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span style={{ color: "#9CA3C5" }}>Email Digest</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.svg"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span style={{ color: "#9CA3C5" }}>
                          24/7 Customer Support
                        </span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.svg"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span style={{ color: "#9CA3C5" }}>
                          Unlimited Product Updates
                        </span>
                      </div>
                    </div>
                    <button className="btn btn-success mt-5">
                      Get Started
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card h-100 mx-0">
                  <div className="card-header ">
                    <h4>Pro</h4>
                  </div>
                  <div className="card-body ">
                    <div className="rightplan">
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.png"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span>1 User Access</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.png"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span>Updated Data Monthly</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.png"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span>Reports</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.png"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span>Competitive Analysis</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.png"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span>Mobile App</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.png"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span>Email Digest</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.svg"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span style={{ color: "#9CA3C5" }}>
                          24/7 Customer Support
                        </span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.svg"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span style={{ color: "#9CA3C5" }}>
                          Unlimited Product Updates
                        </span>
                      </div>
                    </div>
                    <button className="btn btn-success mt-5">
                      Get Started
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card h-100 mx-0">
                  <div className="card-header ">
                    <h4>Ultimate</h4>
                  </div>
                  <div className="card-body">
                    <div className="rightplan">
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.png"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span>1 User Access</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.png"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span>Updated Data Monthly</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.png"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span>Reports</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.png"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span>Competitive Analysis</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.png"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span>Mobile App</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.png"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span>Email Digest</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.png"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span>24/7 Customer Support</span>
                      </div>
                      <div className="plans">
                        <img
                          src="pages/images/vuesax-bold-tick-circle.png"
                          alt="evalmyBRANDcheckmark"
                        />
                        <span>Unlimited Product Updates</span>
                      </div>
                    </div>
                    <button className="btn btn-success mt-5">
                      Get Started
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="testmonials">
                        <div className="container text-center">
                            <div className="owl-carousel owl-theme">

                                <div className="card">
                                    <div className="d-flex justify-content-between">
                                        <img src="pages/images/bud-e.in.svg" alt="budelogo" className="test-img" />
                                        <img src="pages/images/Icon.svg" alt="evalmybrandquoteicon" className="ic" />
                                    </div>
                                    <p class="partners">Our partnership with them has been great. With the evalmyBRAND, we have been able to stay on top of our game. We saved time and resources to track all the sentiments and Voice-of-Customer in the digital space. The tool has enabled us to understand our customers and their needs better.
                                    </p>
                                    <div className=" d-flex">
                                        <img src="pages/images/VijayMadduri.png" alt="vijaymadduri" className="avatar-img" />
                                        <div className="detailes ms-3">
                                            <h5>Vijay Madduri</h5>
                                            <p>CEO</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="card ">
                                    <div className="d-flex justify-content-between">
                                        <img src="pages/images/qualifyde-logo.svg" alt="qualifydelogo" className="test-img" />
                                        <img src="pages/images/Icon.svg" alt="evalmybrandquoteicon" className="ic" />
                                    </div>
                                    <p class="partners">evalmyBRAND has helped Qualifyde grow as a brand. The platform empowered us with essential competition insights. It has enabled us to build strategies and grow ahead of our competitors. We love its opinion categorization of products & services through social media reviews.
                                    </p>
                                    <div className="d-flex">
                                        <img src="pages/images/ShashiKauravlla.png" alt="shashikauravlla" className="avatar-img" />
                                        <div className="detailes ms-3">
                                            <h5>Shashi Kauravlla</h5>
                                            <p>Founding Member</p>
                                        </div>
                                    </div>
                                </div>



                                <div className="card ">
                                    <div className="d-flex justify-content-between">
                                        <img src="pages/images/airdriven_logo.svg" alt="airdrivenlogo" className="test-img" />
                                        <img src="pages/images/Icon.svg" alt="evalmybrandquoteicon" className="ic" />
                                    </div>
                                    <p class="partners">I love evalmyBRAND, as it has saved my team's time monitoring AirDriven's Social Channels. The platform is very user-friendly, and it's convenient to track all comments and sentiments. The ease of assigning brand goals to different teams; helped us make better brand decisions. It's been a pleasure working with them.
                                    </p>
                                    <div className="d-flex">
                                        <img src="pages/images/KedarSelgamsetty.png" alt="kedarselgamsetty" className="avatar-img" />
                                        <div className="detailes ms-2">
                                            <h5>Kedar Selgamsetty,</h5>
                                            <p>Director </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
      </div>
      <div className="testmonials" ref={cref}>
        <div className="container text-center">
          <h4 className="display-4">Testimonials</h4>
          <h3 className="lead mb-2 pt-2">Our Customer's Acclaim</h3>
          <p className="mb-3" style={{ color: "#9ca3c5" }}>
            We offer the best solutions to our customer's business needs and
            they love us more than we do. evalmyBRAND works with a single point
            agenda to provide the top notch solutions to all its customers and
            help them grow exponentially.
          </p>
          {/* <div className="owl-carousel owl-theme"> */}
          <div className="review-cards">
            {/* <Carousel controls={false} indicators={false}>
              {testim.map((tst, id) => (
                <Carousel.Item inteval={100} key={tst.id}>
                  <div className="col-md-4">
                    <div className="card ms-2">
                      <div className="d-flex justify-content-between">
                        <img
                          src={tst.companylogopath}
                          alt="budelogo"
                          className="test-img"
                        />
                        <img
                          src="pages/images/Icon.svg"
                          alt="evalmybrandquoteicon"
                          className="ic"
                        />
                      </div>
                      <p class="partners">{tst.opinion}</p>{" "}
                      <div className="v-detailes">
                        <img
                          src={tst.photopath}
                          alt={tst.name}
                          className="avatar-img"
                        />
                        <div className="detailes ms-2">
                          <h5>{tst.name}</h5>
                          <p class="designation">{tst.designation}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel> */}

            <MultiCarousel
              swipeable={false}
              draggable={false}
              showDots={true}
              arrows={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              // autoPlay={true}
              // keyBoardControl={true}
              // customTransition="all .5"
              // transitionDuration={2000}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              {testim.map((tst, id) => (
                <div>
                  <div className="col-md-12">
                    <div className="card ms-2">
                      <div className="d-flex justify-content-between">
                        <img
                          src={tst.companylogopath}
                          alt="budelogo"
                          className="test-img"
                        />
                        <img
                          src="pages/images/Icon.svg"
                          alt="evalmyBRANDquoteicon"
                          className="ic"
                        />
                      </div>
                      <p className="partners">{tst.opinion}</p>{" "}
                      <div className="v-detailes">
                        <img
                          src={tst.photopath}
                          alt={tst.name}
                          className="avatar-img"
                        />
                        <div className="detailes ms-2">
                          <h5>{tst.name}</h5>
                          <p className="designation">{tst.designation}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </MultiCarousel>
          </div>
          {/* 
          <div className="d-none d-md-block" id="testmonials1">
            <div className="row g-3">
              {testim.map((tst, id) => (
                <div className="col-md-4 ">
                  <div className="card">
                    <div className="d-flex justify-content-between">
                      <img
                        src={tst.companylogopath}
                        alt="budelogo"
                        className="test-img"
                      />
                      <img
                        src="pages/images/Icon.svg"
                        alt="evalmybrandquoteicon"
                        className="ic"
                      />
                    </div>
                    <p class="partners">{tst.opinion}</p>
                    <div className="v-detailes">
                      <img
                        src={tst.photopath}
                        alt={tst.name}
                        className="avatar-img"
                      />
                      <div className="detailes ms-2">
                        <h5>{tst.name}</h5>
                        <p class="designation">{tst.designation}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div> */}
          {/* </div> */}
        </div>
      </div>
      <div className="trending">
        <div className="container ">
          <h4 className="text-center ">What's Trending !</h4>
          <h3 className="text-center mb-5 pt-2">
            Check out the latest trends on social media's impact on businesses . . .
          </h3>

          {/* <div className="owl-carousel owl-theme"> */}
          <div className="showTrends">

          <div className="row">
            {blogs.map((blg, id) => (
              <div className="col-md-4" key={id}>
                <div
                  className="card h-100 mx-0"
                  style={{ border: "0.5px solid #ccc" }}
                  >
                  <div className="card-img">
                    <img
                      src={blg.image}
                      alt="socialmediainfluence"
                      className="w-100 im-fluid"
                      />
                  </div>
                  <div className="card-body position-relative">
                    <h3 className="card-title">{blg.heading}</h3>
                    <p className="card-text pv-3">{blg.content}</p>
                    <a
                      href={blg.link}
                      target="_blank"
                      rel="noreferrer"
                      className="mb-2 position-absolute bottom-0"
                      >
                      <span className="readnow">
                        Read Now{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="18"
                          fill="currentColor"
                          className="bi bi-arrow-right"
                          viewBox="0 0 16 16"
                          >
                          <path
                            fillRule="evenodd"
                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                            />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
  </div>
<div className="hideTrends">
  
          <MultiCarousel
              swipeable={false}
              draggable={false}
              showDots={true}
              arrows={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              // autoPlay={true}
              // keyBoardControl={true}
              // customTransition="all .5"
              // transitionDuration={2000}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              {blogs.map((blg, id) => (
                <div>
                  <div className="col-md-4" key={id}>
                <div
                  className="card h-100 mx-0"
                  style={{ border: "0.5px solid #ccc" }}
                >
                  <div className="card-img">
                    <img
                      src={blg.image}
                      alt="socialmediainfluence"
                      className="w-100 im-fluid"
                    />
                  </div>
                  <div className="card-body position-relative">
                    <h3 className="card-title">{blg.heading}</h3>
                    <p className="card-text pv-3">{blg.content}</p>
                    <a
                      href={blg.link}
                      target="_blank"
                      rel="noreferrer"
                      className="mb-2 position-absolute bottom-0"
                    >
                      <span className="readnow">
                        Read Now{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="18"
                          fill="currentColor"
                          className="bi bi-arrow-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
                </div>
              ))}
            </MultiCarousel>
            
</div>
          {/* </div> */}
        </div>
      </div>

      <div className="subscribe">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="news-letter">
                <h2>Subscribe to our News Letter</h2>
                <h4 className="mt-4">
                  Subscribe and Receive all the best practices, news and product
                  updates.
                </h4>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="news-letter">
                <img
                  src="pages/images/design.png"
                  alt="evalmynewsdesign"
                  className="design"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="email">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Email Address"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                  <span className="input-group-text" id="basic-addon1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="18"
                      fill="currentColor"
                      className="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        {/* <div className="social-img"></div> */}
        <div className="container" style={{ paddingBottom: '40px' }}>
          <div className="row ">
            <div className="col-lg-2"></div>
            <div className="col-lg-6">
              <div className="d-flex br">
                <img
                  src="pages/images/gdpr.png"
                  alt="gdprlogo"
                  className="br-logo"
                />
                <img
                  src="pages/images/iso.png"
                  alt="isologo"
                  className="br-logo"
                />
                <img
                  src="pages/images/soc.png"
                  alt="soclogo"
                  className="br-logo"
                />
                <img
                  src="pages/images/skyhigh.png"
                  alt="skyhighlogo"
                  className="br-logo"
                />
              </div>
            </div>
          </div>
          <div className="row   justify-content-center mt-5">
            <div className="col-lg-3 col-md-6">
              <div className="s-brand">
                <a href="/">
                  <img
                    src="pages/images/evalmybrand.png"
                    className="s-img"
                    alt="evalmyBRANDmoblogo"
                  />
                </a>
                <p className="mission mt-2 mb-2">
                  Our mission is to empower you with the knowledge and tools you need to navigate the rapidly changing landscape of social media and customer experience.
                </p>
                <div className="social-imp">
                  <a
                    href="https://www.facebook.com/people/evalmyBRAND/100086047461629/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="pages/images/social-facebook.png"
                      alt="facebooklogo"
                      className="social"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/evalmybrand/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="pages/images/social-linkedin.png"
                      alt="linkedinlogo"
                      className="social"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/evalmybrand/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="pages/images/social-insta.png"
                      alt="instagramlogo"
                      className="social"
                    />
                  </a>
                  <a href=" https://twitter.com/evalmyBrand" target="_blank">
                    <img
                      src="pages/images/social-twitter.png"
                      alt="twitterlogo"
                      className="social"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCOs9P0nxGKD8uCDWWHRxgkw/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="pages/images/social-youtube.png"
                      alt="youtubelogo"
                      className="social"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="main-detailes mb-3" id="main-m">
                <a href="https://evalmybrand.com/">
                  <h3>Company</h3>
                </a>
                <a href="/about">
                  <p>About evalmyBRAND</p>
                </a>
                <a href="/pricing">
                  <p>Pricing</p>
                </a>
                <a href="https://blog.evalmybrand.com/" target="_blank" rel="noreferrer" >
                  <p>News & Blog</p>
                </a>
                <a href="/#favourite">
                  <p onClick={executeScroll}>Customers</p>
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="main-detailes">
                <a href="features">
                  <h3>Features</h3>
                </a>
                <a href="features#insight">
                  <p>Insights & Analytics</p>
                </a>
                <a href="features#sociallistening">
                  <p>Social Listening</p>
                </a>
                <a href="features#socialmediamonitoring">
                  <p>Social Media Monitoring</p>
                </a>
                <a href="features#appanalysis">
                  <p>App Analysis</p>
                </a>
                <a href="features#campaignanalyzer">
                  <p>Campaign Analyzer</p>
                </a>
                <a href="features#casemanagement">
                  <p>Case Management</p>
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="main-detailes mb-5">
                <h3>Support & Legal</h3>
                <a href="/faqs" target="_blank" rel="noreferrer" >
                  <p>FAQs</p>
                </a>
                <a href="/getstarted">
                  <p>Contact Us</p>
                </a>
                <a href="/terms" target="_blank" className="nav-link active">
                  <p className="d-1">Terms of Use</p>
                </a>
                <a href="/policies" target="_blank" className="nav-link active">
                  <p className="d-1">Privacy Policy</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr style={{ marginBottom: '0.5rem' }} />
        <div className="container">
          <div className="footer-detailes">
            <div className="d-flex detailes">
              <div className="copy">
                <p style={{ marginBottom: '0.5rem' }}>© evalmyBRAND 2023. All Rights Reserved </p>
                <p style={{ display: "none" }}>01.09</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <a href="/sales">
              <button className="btn btn-primary ms-2 requestdemobtn">Request Demo</button>
            </a> */}
      <Modal open={open} onClose={onCloseModal} center>
        <ReactPlayer className='demo'
          url="https://www.youtube.com/watch?v=1QYXW5Ex-4A/"
          playing={true}
        />
      </Modal>
    </div>
  );
}

export default Home;
