import React, { useState } from 'react';
import axios from 'axios';
import validator from 'validator';
// import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
function Sales() {
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    // const [country, setcountry] = useState('');
    // const [phonenumber, setmobilenumber] = useState('');
    const [email, setemail] = useState('');
    const [orgname, setorgname] = useState('');
    const [requestDate, setrequestDate] = useState('');
    const [industry, setindustry] = useState('');
    const [submitmsg, setsubmitmsg] = useState('');
    // const navigate = useNavigate();
    const [showMessage,setshowMessage] = useState(true);
    async function onSubmit() {


        if (firstName === '' || lastName === '' || email === ''  || industry === 'Select Industry Type' || orgname === '') {
            alert('All Fields are Required...!!!');
            return;
        }
        if (!validator.isEmail(email)) {
            alert('Invalid Email Address...!!!');
            return;
        }
        let dt = requestDate
        let d = dt.split("T")[0]
        let t = dt.split("T")[1]
        let fd = d.split("-")[2] + "-" + d.split("-")[1] + "-" + d.split("-")[0] + " " + t.split(":")[0] + ":" + t.split(":")[1] + ":00"

        const params = JSON.stringify({
            "firstName": firstName,
            "lastName": lastName,
            "email": email,
            "orgName": orgname,
            "requestDate": fd,
            "industry": industry,
            // "country": country,
            // "phonenumber": phonenumber,
        });
        console.log(params);
        //let url = "/crm/v2/functions/evmbleadcreation/actions/execute?auth_type=apikey&zapikey=1003.a176546d9c5106ea7d5debbf0d65a305.192b2d1fea5955babecdc7664394460d"
        let url = "https://uat-api.evalmybrand.com/zoho/create/lead";
        axios.post(url, params, {
            "headers": {
                'Content-Type': 'application/json',
            }
        })
            .then(function (response) {
                setsubmitmsg('');
                console.log(response);
                console.log(response.data.success);
                if (response.data.msg === 'Saved successfully') {
                    // alert('Data Submitted Successfully...!!!');
                    //     window.location.href="/"
                    setsubmitmsg('Data Saved Successfully...');
                    // navigate("/thankyou");
                    setshowMessage(false)
                }
                else {
                    setsubmitmsg("Unable to submit data...");
                }
            })
            .catch(function (error) {
                console.log(error);
                setsubmitmsg('Something went wrong...');
            });

    }
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>sales | evalmyBRAND</title>
            </Helmet>
            <div className="sales">
                <div className="container">
                    {/* <div className="talk-to-sale">
                        <div className="talk-to-sale-height">
                            <div className="inner-content">
                        <div class="d-flex">
                            <a href="/"><img src="pages/images/brand-logo.png" alt="" /></a>
                                <h2 className="pb-0">Talk to Sales</h2></div>
                                <hr />
                                <div id="status-message"></div>
                                <form id="sales-form">
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <label htmlFor="fname">First Name</label><br />
                                            <input type="text" id="fname" value={firstName} onChange={(e) => setfirstName(e.target.value)} />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="lname">Last Name</label><br />
                                            <input type="text" id="lname" value={lastName} onChange={(e) => setlastName(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <label htmlFor="email">Email Address</label><br />
                                            <input type="text" id="email" value={email} onChange={(e) => setemail(e.target.value)} />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="oname">Organization Name</label><br />
                                            <input type="text" id="loame" value={orgname} onChange={(e) => setorgname(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">

                                        <div className="col-md-6">
                                            <label htmlFor="industry">Industry Type</label>
                                            <select value={industry} defaultValue={'Select Industry Type'} onChange={(e) => setindustry(e.target.value)} className="form-select" id="industry" aria-label="Default select example">
                                                <option value="Select Industry Type">Select Industry Type</option>
                                                <option value="messaging">Messaging</option>
                                                <option value="musicAndAudio">Music & Audio</option>
                                                <option value="videoStreaming">Video Streaming</option>
                                                <option value="social">Social</option>
                                                <option value="news">news</option>
                                                <option value="photography">Photography</option>
                                                <option value="books">Books</option>
                                                <option value="lifeStyle">Life Style</option>
                                                <option value="shopping">Shopping</option>
                                                <option value="weather">Weather</option>
                                                <option value="sports">Sports</option>
                                                <option value="entertainment">Entertainment (OTT, ATT)</option>
                                                <option value="Health&Fitness">Health and Fitness</option>
                                                <option value="Games">Games</option>
                                                <option value="foodDelivery">Food Delivery</option>
                                                <option value="F&B">F&B</option>
                                                <option value="dating">Dating</option>
                                                <option value="mobility">Mobility</option>
                                                <option value="events">Events</option>
                                                <option value="auto">Auto</option>
                                                <option value="business">Business</option>
                                                <option value="travel&Hospitality">Travel & Hospitality</option>
                                                <option value="eCommerce">E-commerce</option>
                                                <option value="aviation">Aviation</option>
                                                <option value="grocery">Grocery</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="start">Select Date & Time</label>
                                            <input type="datetime-local" id="start" value={requestDate} onChange={(e) => setrequestDate(e.target.value)} />
                                        </div>
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-3">
                                           <a href="/"><button className="btn btn-primary me-md-2" type="button">Cancel</button></a>
                                            <button className="btn btn-primary" onClick={onSubmit} type="button">Submit</button>
                                        </div>
                                        
                                        <div className="col-md-6">
                                           {submitmsg} </div>
                            </div>
                                </form>
                            </div>
                        </div>
                    </div> */}
                    <div className='contactMainBlock'>
                        <div className='contactLeftBlock' >
                            <div style={{paddingTop:'25px'}}>
                            <div style={{fontSize: "34px",lineHeight:'40px' }}>
                            Discover how <span style={{ color: '#1BB5F9', fontWeight: "600" }}>evalmyBRAND</span> can enhance your <br />
                            <span style={{ color: '#1BB5F9', fontWeight: "600"}}> Customer Engagement</span>
                            </div>
                            <p style={{color:"#C4C4C4",fontSize:"18px",lineHeight:'30px',marginTop:'15px'}}>Our mission is to empower you with the knowledge and tools you need to navigate the rapidly changing landscape of social media and customer experience.</p>
                            <p style={{color:"#fff",fontSize:"24px",marginTop:'50px'}}>Book a meeting with us NOW!</p>
                            <ul style={{color:"#C4C4C4",fontSize:"16px",listStyleType:"none",margin:'0',padding:'0'}}>
                                <li><img src='pages/images/circle tick.svg' alt='circle' style={{width:"15px",height:"15px",marginRight:'10px',marginTop:'-2px'}}/>Personalised Demo</li>
                                <li><img src='pages/images/circle tick.svg' alt='circle' style={{width:"15px",height:"15px",marginRight:'10px',marginTop:'-2px'}}/>SWOT & Recommendations Report</li>
                                <li><img src='pages/images/circle tick.svg' alt='circle' style={{width:"15px",height:"15px",marginRight:'10px',marginTop:'-2px'}}/>Growth Prospects & Industry Trends</li>
                            </ul>
                            <div className='footerLogossalaes'>
                                <img src='pages/images/taim.png' alt='footerbrnads'/>
                                <img src='pages/images/microsoftstartup.png' alt='footerbrnads'/>
                            </div>
                            </div>
                        </div>
                        <div className='contactRightBlock'>
                            <div className='contactRightBlockInside'>
                            {/* <p style={{color:"#1BB5F9",fontWeight:"600"}}>Get Started</p> */}
                            {(showMessage ) && (
                                <form id="sales-form">
                                <h3 className='showGet hideTrends'>Get Standard</h3>
                                        <div className="col-md-12">
                                            <label  className="Contactlabel" htmlFor="fname">First Name</label><br />
                                            <input className='contactInputFields'  type="text" id="fname" value={firstName} onChange={(e) => setfirstName(e.target.value)} />
                                        </div>
                                        <div className="col-md-12">
                                            <label  className="Contactlabel" htmlFor="lname">Last Name</label><br />
                                            <input className='contactInputFields' type="text" id="lname" value={lastName} onChange={(e) => setlastName(e.target.value)} />
                                        </div>
                                        <div className="col-md-12">
                                            <label  className="Contactlabel" htmlFor="email">Email Address</label><br />
                                            <input className='contactInputFields' type="text" id="email" value={email} onChange={(e) => setemail(e.target.value)} />
                                        </div>
                                        <div className="col-md-12">
                                            <label  className="Contactlabel" htmlFor="oname">Organization Name</label><br />
                                            <input className='contactInputFields' type="text" id="loame" value={orgname} onChange={(e) => setorgname(e.target.value)} />
                                        </div>

                                        <div className="col-md-12">
                                            <label  className="Contactlabel" htmlFor="industry">Industry Type</label>
                                            <select value={industry} defaultValue={'Select Industry Type'} onChange={(e) => setindustry(e.target.value)} className="form-select contactInputFields" id="industry" aria-label="Default select example">
                                                <option value="Select Industry Type">Select Industry Type</option>
                                                <option value="messaging">Messaging</option>
                                                <option value="musicAndAudio">Music & Audio</option>
                                                <option value="videoStreaming">Video Streaming</option>
                                                <option value="social">Social</option>
                                                <option value="news">news</option>
                                                <option value="photography">Photography</option>
                                                <option value="books">Books</option>
                                                <option value="lifeStyle">Life Style</option>
                                                <option value="shopping">Shopping</option>
                                                <option value="weather">Weather</option>
                                                <option value="sports">Sports</option>
                                                <option value="entertainment">Entertainment (OTT, ATT)</option>
                                                <option value="Health&Fitness">Health and Fitness</option>
                                                <option value="Games">Games</option>
                                                <option value="foodDelivery">Food Delivery</option>
                                                <option value="F&B">F&B</option>
                                                <option value="dating">Dating</option>
                                                <option value="mobility">Mobility</option>
                                                <option value="events">Events</option>
                                                <option value="auto">Auto</option>
                                                <option value="business">Business</option>
                                                <option value="travel&Hospitality">Travel & Hospitality</option>
                                                <option value="eCommerce">E-commerce</option>
                                                <option value="aviation">Aviation</option>
                                                <option value="grocery">Grocery</option>
                                            </select>
                                        </div>
                                        <div className="col-md-12">
                                            <label  className="Contactlabel" htmlFor="start">Select Date & Time</label><br />
                                            <input className='contactInputFields' type="datetime-local" id="start" value={requestDate} onChange={(e) => setrequestDate(e.target.value)} />
                                        </div>
                                          {/* <div className="col-md-12">
                                            <label  className="Contactlabel" htmlFor="lname">Country</label><br />
                                            <input className='contactInputFields' type="text" id="lname" value={country} onChange={(e) => setcountry(e.target.value)} />
                                        </div> */}
                                        {/* <div className="col-md-12">
                                            <label  className="Contactlabel" htmlFor="email">Phone Number</label><br />
                                            <input className='contactInputFields' type="number" id="email" value={phonenumber} onChange={(e) => setmobilenumber(e.target.value)} />
                                        </div> */}
                                        <div className="d-grid gap-2 d-md-flex justify-content-center mt-3">
                                            <button className="btn btn-primary" style={{background:"rgb(3, 162, 233)"}} onClick={onSubmit} type="button">Submit Request</button>
                                            {/* <br /> */}
                                        </div>
                                        <div style={{textAlign:"center",marginTop:'10px'}}>

                                           <a href="/" style={{margin:"0 auto"}}>
                                            <img src='pages/images/left-arrow.svg' alt='left arrow' style={{width:"17px"}}/>
                                            <span className="me-md-2" style={{fontSize:'10px'}} type="button">Back to Website</span></a>
                                        </div>
                                        
                                        <div className="col-md-12">
                                           {submitmsg} </div>
                                </form>
                            )}
                            {(!showMessage ) && (
                                <>
                                <div style={{textAlign:"center",marginTop:"calc(100vh - 130%)"}}>
<img src='pages/images/righttick.png' alt='right tick' style={{width:'50px'}} />
<br />
<br />
                                Thank you for your interest!<br /> Our team will get in touch shortly.
     <div style={{textAlign:"center",marginTop:'10px'}}>

     <a href="/" style={{margin:"0 auto"}}>
      <img src='pages/images/left-arrow.svg'  alt='left arrow' style={{width:"17px"}}/>
      <span className="me-md-2" style={{fontSize:'10px'}} type="button">Back to Website</span></a>
                                </div>
  </div>
                                </>


                                
                            )}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Sales;