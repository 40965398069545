import React, {  useRef } from "react";
// import { HashLink as Link } from "react-router-hash-link";
import { Helmet } from "react-helmet";
function About() {

  // const myRef = useRef(null);
  const tref = useRef(null);
  // const cref = useRef(null);
  const executeScroll = () => {
    if (window.innerWidth > 767) {
      window.scrollTo({
        top: tref.current.offsetTop - 200,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: tref.current.offsetTop - 100,
        behavior: "smooth",
      });
    }
  };
  // const gotopricing = () => {
  //   if (window.innerWidth > 767) {
  //     window.scrollTo({
  //       top: cref.current.offsetTop - 300,
  //       behavior: "smooth",
  //     });
  //   } else {
  //     window.scrollTo({
  //       top: cref.current.offsetTop - 140,
  //       behavior: "smooth",
  //     });
  //   }
  // };
  // const gotoproducts = () => {
  //   if (window.innerWidth > 767) {
  //     window.scrollTo({
  //       top: myRef.current.offsetTop - 300,
  //       behavior: "smooth",
  //     });
  //     console.log("system " + window.innerWidth);
  //   } else {
  //     window.scrollTo({
  //       top: myRef.current.offsetTop - 100,
  //       behavior: "smooth",
  //     });
  //     console.log("Mobile " + window.innerWidth);
  //   }
  // };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>evalmyBRAND's Moto & Vision | evalmyBRAND </title>
        <meta
          name="description"
          content="evalmyBRAND is unified social listening platform which helps brands to improve the targeting accuracy and automates the management of social media communications."
        />
        <meta name="keywords" content="evalmyBRAND,About evalmyBRAND" />
      </Helmet>
      <div className="bg">
        <div id="main-navbar">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <a className="navbar-brand" href="/">
                <img
                  src="/pages/images/logo-brand-black.png"
                  alt="evalmyBRANDlogo"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                  {/* <li className="nav-item">
                    <a className="nav-link" href="/">
                      Home
                    </a>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link
                      to="/features"
                      scroll={(el) =>
                        el.scrollIntoView({ behavior: "auto", block: "end" })
                      }
                    >
                      Home
                    </Link>
                  </li> */}

                  <li className="nav-item">
                    <a className="nav-link active" href="/about">
                      About Us
                    </a>
                  </li>
                </ul>
                <div className="nav-btns d-flex">
                  <a
                    href="https://portal.evalmybrand.com"
                    className="mt-1 nav-link active"
                    target="_blank"
                    rel="noreferrer" 
                  >
                    Login
                  </a>
                  <a href="/getstarted">
                    <button className="btn btn-primary">Request Demo</button>
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div id="about" title="aboutevalmyBRAND">

        <div className="home-page-section positon-sticky" id="header">
          <nav className="navbar navbar-expand-lg" id="navbar">
            <div className="container-fluid">
              <a className="navbar-brand" href="https://evalmybrand.com/">
                <img
                  src="pages/images/evalmybrand-Logo.png"
                  alt="evalmyBRANDlogo"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  {/* <li className="nav-item">
                  <a
                    className="nav-link"
                    aria-current="page"
                    href="https://evalmybrand.com/"
                  >
                    Home
                  </a>
                </li> */}
                  <li className="nav-item">
                    <a className="nav-link active" href="/about">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="features"
                    >
                      Features
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link"
                      href="/pricing"
                    >
                      Pricing
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="https://blog.evalmybrand.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      News & Blog
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/getstarted">
                      Contact Us
                    </a>
                  </li>
                </ul>
                <div className="nav-btns d-flex hide">
                  <a
                    href="https://portal.evalmybrand.com"
                    className="mt-1 nav-link active"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Login
                  </a>
                  {/* <a href="/sales">
                  <button className="btn btn-primary">Request Demo</button>
                </a> */}
                </div>
              </div>
            </div>
          </nav>
          <nav className="navbar navbar-expand-lg" id="navbar-mob">
            <div className="container-fluid">
              <a className="navbar-brand-mob" href="/">
                <img
                  src="/pages/images/logo-brand-white.png"
                  alt="evalmyBRANDlogo"
                />
              </a>

              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  {/* <li className="nav-item">
                    <a className="nav-link" href="/">
                      Home
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a className="nav-link" href="/features">
                      Features
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link active" href="/about">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="https://blog.evalmybrand.com/"
                      target="_blank"
                      rel="noreferrer" 
                    >
                      Blog
                    </a>
                  </li>
                </ul>
                <div className="m-btns d-flex">
                  <a
                    href="https://portal.evalmybrand.com"
                    className="mt-1 nav-link active"
                    target="_blank" rel="noreferrer" 
                  >
                    Login
                  </a>
                  <a href="/getstarted">
                    <button className="btn btn-primary">Request Demo</button>
                  </a>
                </div>
              </div>
            </div>
          </nav>
          {/* <a href="/">
            <div className="left-arrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                className="bi bi-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                />
              </svg>
            </div>
          </a> */}
          {/* <div className="in-ab-text">
            <div className="container text-center">
              <h2 className="text-center mb-3">About Us</h2> 
            </div>
          </div> */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 aboutBlock">
          <video src='pages/images/video.mp4' width="100%" height="500" controls={false} autoPlay={true} muted={true} style={{ marginTop: "4.5rem" }}>
          </video>
        </div>
      </div>
      <div className="destination mt-0 pt-2">
        <div className="container mb-3">
          {/* <h3 className="mb-3">
            If intent is the destination, then data is the fuel to reach there.
          </h3> */}
          {/* <p>
            Brands are built on solid ideas, plans, and execution. The idea is
            fundamental to the existence of a company. As part of planning and
            execution, the brand must develop products and deliver them to their
            customers. The world we live in today is driven by data, and
            data-driven decision- making is at the heart of planning and
            execution.{" "}
          </p>
          <p>
            We at evalmyBRAND utilise powerful AI Algorithms, Natural Language
            Processing and Sentiment analysis to provide insights about your
            brand to accelerate planning and execution. Thus, fueled by data &
            insights from our powerful AI base model, evalmyBRAND helps you reach your
            brand goals.{" "}
          </p> */}
          <p className="textJ">
            Welcome to evalmyBRAND, where the power of social listening and cutting-edge AI converge to transform the way you understand and elevate your brand.</p><p className="textJ">

            At evalmyBRAND, we are passionate about helping businesses and individuals harness the immense potential of their online presence. Our platform is more than just a social listening and monitoring tool; it's a gateway to unlocking the secrets of your brand's success.</p><p className="textJ">

            With our state-of-the-art AI features, we empower you to dive deep into the vast ocean of online conversations and uncover valuable insights. Our advanced algorithms analyze millions of data points, extracting meaningful patterns, sentiment trends, and topic clusters in real-time. This wealth of information enables you to make data-driven decisions, seize opportunities, and craft effective strategies that resonate with your target audience.</p><p className="textJ">

            We believe that knowledge is power, and that's why we go beyond basic monitoring. Our platform equips you with an intuitive dashboard that presents actionable insights in a visually stunning and easily digestible manner. Whether you're a marketing professional, an entrepreneur, or an influencer, evalmyBRAND puts the power of big data at your fingertips.</p><p className="textJ">

            But our commitment to excellence doesn't stop there. Our soon to come, CXM suite will empower you to map customer journeys, analyze feedback, and gain comprehensive insights into the customer experience at every touchpoint. By understanding the emotions, preferences, and pain points of your customers, you can proactively address issues, optimize your offerings, and create personalized experiences that resonate with your audience.
          </p>
          <div className="brandsCXMImg">
          <img src="/pages/images/brands.png" alt="evalmyteam"/>
          </div>
          <p className="textJ">
            Behind the scenes, our dedicated team of industry experts and data scientists work tirelessly to innovate and push the boundaries of social listening technology. We strive to stay one step ahead, continuously enhancing our platform with new features and functionalities that reflect the latest advancements in AI and machine learning.
          </p><p className="textJ">
            At evalmyBRAND, we believe that every brand has a unique story to tell, and we're here to help you amplify that story to new heights. Join us today and embark on a transformative journey that will revolutionize the way you understand, shape, and elevate your brand's online presence.
          </p><p className="textJ">
            Together, let's unlock the full potential of your brand in the digital landscape.
          </p>
        </div>
      </div>

      <div className="container insight" style={{ display: 'none' }}>
        <div className="row">
          <div className="col-md-12">
            <div className="">
              <div className="growth">
                <h2 className="connect">
                  Connecting you better with your customers is our goal as we
                  decode the social matrix for you.
                </h2>
                <p className="metrics">
                  <span style={{ color: "#000", marginRight: "10px" }}>
                    &#9679;
                  </span>{" "}
                  Achieve critical insights from the data
                </p>
                <p className="metrics">
                  <span style={{ color: "#000", marginRight: "10px" }}>
                    &#9679;
                  </span>
                  Sentiment analysis of brands
                </p>
                <p className="metrics">
                  <span style={{ color: "#000", marginRight: "10px" }}>
                    &#9679;
                  </span>{" "}
                  Measures engagement and provides you with metrics
                </p>
                <p className="metrics">
                  <span style={{ color: "#000", marginRight: "10px" }}>
                    &#9679;
                  </span>
                  Analyzes social media
                </p>
                <p className="metrics">
                  <span style={{ color: "#000", marginRight: "10px" }}>
                    &#9679;
                  </span>
                  An analysis of the competition
                </p>
                <p className="metrics">
                  <span style={{ color: "#000", marginRight: "10px" }}>
                    &#9679;
                  </span>
                  Quick emergency redressal
                </p>
                <p className="metrics">
                  <span style={{ color: "#000", marginRight: "10px" }}>
                    &#9679;
                  </span>
                  Helps in Brand goal setting and attainment
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="about" title="aboutevalmyBRAND"></div>
          </div>
        </div>
      </div>
      <div className="team" style={{ display: 'none' }}>
        <div className="container">
          <h4 className="text-center mb-4">Our Team</h4>
          <h3 className="text-center mb-5">
            True Leaders Don't Create Followers. They Create More Leaders.
          </h3>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-3 mb-3">
              <div className="team-member">
                <img src="/pages/images/team-1.png" alt="evalmyteam" />
              </div>
            </div>
            <div className="col-md-12 col-lg-3 mb-3">
              <div className="team-member">
                <img src="/pages/images/team-1.png" alt="evalmyteam" />
              </div>
            </div>
            <div className="col-md-12 col-lg-3 mb-3">
              <div className="team-member">
                <img src="/pages/images/team-1.png" alt="evalmyteam" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        {/* <div className="social-img"></div> */}
        <div className="container" style={{ paddingBottom: '40px' }}>
          <div className="row ">
            <div className="col-lg-2"></div>
            <div className="col-lg-6">
              <div className="d-flex br">
                <img
                  src="pages/images/gdpr.png"
                  alt="gdprlogo"
                  className="br-logo"
                />
                <img
                  src="pages/images/iso.png"
                  alt="isologo"
                  className="br-logo"
                />
                <img
                  src="pages/images/soc.png"
                  alt="soclogo"
                  className="br-logo"
                />
                <img
                  src="pages/images/skyhigh.png"
                  alt="skyhighlogo"
                  className="br-logo"
                />
              </div>
            </div>
          </div>
          <div className="row   justify-content-center mt-5">
            <div className="col-lg-3 col-md-6">
              <div className="s-brand">
                <a href="/">
                  <img
                    src="pages/images/evalmybrand.png"
                    className="s-img"
                    alt="evalmyBRANDmoblogo"
                  />
                </a>
                <p className="mission mt-2 mb-2">
                  Our mission is to empower you with the knowledge and tools you need to navigate the rapidly changing landscape of social media and customer experience.
                </p>
                <div className="social-imp">
                  <a
                    href="https://www.facebook.com/people/evalmyBRAND/100086047461629/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="pages/images/social-facebook.png"
                      alt="facebooklogo"
                      className="social"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/evalmybrand/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="pages/images/social-linkedin.png"
                      alt="linkedinlogo"
                      className="social"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/evalmybrand/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="pages/images/social-insta.png"
                      alt="instagramlogo"
                      className="social"
                    />
                  </a>
                  <a href=" https://twitter.com/evalmyBrand" target="_blank">
                    <img
                      src="pages/images/social-twitter.png"
                      alt="twitterlogo"
                      className="social"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCOs9P0nxGKD8uCDWWHRxgkw/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="pages/images/social-youtube.png"
                      alt="youtubelogo"
                      className="social"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="main-detailes mb-3" id="main-m">
                <a href="https://evalmybrand.com/">
                  <h3>Company</h3>
                </a>
                <a href="/about">
                  <p>About evalmyBRAND</p>
                </a>
                <a href="/pricing">
                  <p>Pricing</p>
                </a>
                <a href="https://blog.evalmybrand.com/" target="_blank" rel="noreferrer" >
                  <p>News & Blog</p>
                </a>
                <a href="/#favourite">
                  <p onClick={executeScroll}>Customers</p>
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="main-detailes">
                <a href="features">
                  <h3>Features</h3>
                </a>
                <a href="features#insight">
                  <p>Insights & Analytics</p>
                </a>
                <a href="features#sociallistening">
                  <p>Social Listening</p>
                </a>
                <a href="features#socialmediamonitoring">
                  <p>Social Media Monitoring</p>
                </a>
                <a href="features#appanalysis">
                  <p>App Analysis</p>
                </a>
                <a href="features#campaignanalyzer">
                  <p>Campaign Analyzer</p>
                </a>
                <a href="features#casemanagement">
                  <p>Case Management</p>
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="main-detailes mb-5">
                <h3>Support & Legal</h3>
                <a href="/faqs" target="_blank" rel="noreferrer" >
                  <p>FAQs</p>
                </a>
                <a href="/getstarted">
                  <p>Contact Us</p>
                </a>
                <a href="/terms" target="_blank" className="nav-link active">
                  <p className="d-1">Terms of Use</p>
                </a>
                <a href="/policies" target="_blank" className="nav-link active">
                  <p className="d-1">Privacy Policy</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr style={{ marginBottom: '0.5rem' }} />
        <div className="container">
          <div className="footer-detailes">
            <div className="d-flex detailes">
              <div className="copy">
                <p style={{ marginBottom: '0.5rem' }}>© evalmyBRAND 2023. All Rights Reserved </p>
                <p style={{ display: "none" }}>01.09</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default About;
