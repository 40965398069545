import React from "react";
// import { HashLink as Link } from "react-router-hash-link";
import { Helmet } from "react-helmet";
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
function Pricing() {

  // const myRef = useRef(null);
  // const tref = useRef(null);
  // const cref = useRef(null);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>evalmyBRAND's Moto & Vision | evalmyBRAND </title>
        <meta
          name="description"
          content="evalmyBRAND is unified social listening platform which helps brands to improve the targeting accuracy and automates the management of social media communications."
        />
        <meta name="keywords" content="evalmyBRAND,About evalmyBRAND" />
      </Helmet>
      {/* <div className="bg">
        <div id="main-navbar">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <a className="navbar-brand" href="/">
                <img
                  src="/pages/images/logo-brand-black.png"
                  alt="evalmyBRANDlogo"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mx-auto mb-2 mb-lg-0"> 

                  <li className="nav-item">
                    <a className="nav-link active" href="/about">
                      About Us
                    </a>
                  </li>
                </ul>
                <div className="nav-btns d-flex">
                  <a
                    href="https://portal.evalmybrand.com"
                    className="mt-1 nav-link active"
                    target="_blank"
                  >
                    Login
                  </a> 
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div> */}
      <div id="about" title="aboutevalmyBRAND">

        <div className="home-page-section positon-sticky" id="header">
          <nav className="navbar navbar-expand-lg" id="navbar">
            <div className="container-fluid">
              <a className="navbar-brand" href="https://evalmybrand.com/">
                <img
                  src="pages/images/evalmybrand-Logo.png"
                  alt="evalmyBRANDlogo"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  {/* <li className="nav-item">
                    <a
                      className="nav-link"
                      aria-current="page"
                      href="https://evalmybrand.com/"
                    >
                      Home
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a className="nav-link" href="/about">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link "
                      href="features"
                    >
                      Features
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link active"
                      href="/pricing"
                    >
                      Pricing
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="https://blog.evalmybrand.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      News & Blog
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/getstarted">
                      Contact Us
                    </a>
                  </li>
                </ul>
                <div className="nav-btns d-flex hide">
                  <a
                    href="https://portal.evalmybrand.com"
                    className="mt-1 nav-link active"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Login
                  </a>
                  {/* <a href="/sales">
                  <button className="btn btn-primary">Request Demo</button>
                </a> */}
                </div>
              </div>
            </div>
          </nav>
          <nav className="navbar navbar-expand-lg" id="navbar-mob">
            <div className="container-fluid">
              <a className="navbar-brand-mob" href="/">
                <img
                  src="/pages/images/logo-brand-white.png"
                  alt="evalmyBRANDlogo"
                />
              </a>

              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link" href="/">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/features">
                      Features
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link active" href="/about">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="https://blog.evalmybrand.com/"
                      target="_blank" rel="noreferrer" 
                    >
                      Blog
                    </a>
                  </li>
                </ul>
                <div className="m-btns d-flex hide">
                  <a
                    href="https://portal.evalmybrand.com"
                    className="mt-1 nav-link active"
                    target="_blank" rel="noreferrer" 
                  >
                    Login
                  </a>
                  {/* <a href="/sales">
                    <button className="btn btn-primary">Request Demo</button>
                  </a> */}
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div className="price" id="pricing">
        <div className="container">
          {/* <h1 className="priceMainTitle">Pricing and Partnership</h1> */}
          <Tabs
            defaultActiveKey="home"
            id="justify-tab-example"
            className="mb-3"
            justify
          >
            <Tab eventKey="home" title="Plans">
              <div className="priceMainModel">
                <div className="priceModel">
                  <div className="priceType silvermodel"></div >
                  <div className="priceAmountBlock">
                    <p className="modelheading">Features</p>
                    <ul className="pricinglist">
                      <li><img src='pages/images/right.svg' alt='icon' className="tickmark" /> Self Brand Analysis</li>
                      <li><img src='pages/images/right.svg' alt='icon' className="tickmark" />Competitor Analysis</li>
                      <li><img src='pages/images/right.svg' alt='icon' className="tickmark" />STAC Analysis</li>
                      <li><img src='pages/images/right.svg' alt='icon' className="tickmark" />CASE Management</li>
                      <li>
                        <img src='pages/images/wrong.svg' alt='icon' className="tickmark" />Recommendations & SWOT
                        Analysis Report</li>
                      <li><img src='pages/images/wrong.svg' alt='icon' className="tickmark" />Campaign Reporting</li>
                      <li><img src='pages/images/wrong.svg' alt='icon' className="tickmark" />Mentions & Hashtags</li>
                      <li><img src='pages/images/wrong.svg' alt='icon' className="tickmark" />Keyword based live dashboard</li>
                    </ul>
                    <p className="modelheading">Supported Channels</p>
                    <ul className="prcingSocial">
                      <li><img src="pages/images/homeicon1.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon2.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon3.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon4.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon5.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon6.png" alt='icon' /></li>
                    </ul>
                    <ul className="prcingSocial">
                      <li><img src="pages/images/homeicon7.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon8.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon9.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon10.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon11.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon12.png" alt='icon' /></li>
                    </ul>
                    <div className="onetime silverOnetime"><a href='getstarted'>Get Started</a></div>
                  </div>
                </div>
                <div className="priceModel">
                  <div className="priceType goldmodel"></div >
                  <div className="priceAmountBlock">
                    <p className="modelheading">Features</p>
                    <ul className="pricinglist">
                      <li><img src='pages/images/right.svg' alt='icon' className="tickmark" /> Self Brand Analysis</li>
                      <li><img src='pages/images/right.svg' alt='icon' className="tickmark" />Competitor Analysis</li>
                      <li><img src='pages/images/right.svg' alt='icon' className="tickmark" />STAC Analysis</li>
                      <li><img src='pages/images/right.svg' alt='icon' className="tickmark" />CASE Management</li>
                      <li><img src='pages/images/right.svg' alt='icon' className="tickmark" />Recommendations & SWOT Analysis Report</li>
                      <li><img src='pages/images/right.svg' alt='icon' className="tickmark" />Campaign Reporting</li>
                      <li><img src='pages/images/wrong.svg' alt='icon' className="tickmark" />Mentions & Hashtags</li>
                      <li><img src='pages/images/wrong.svg' alt='icon' className="tickmark" />Keyword based live dashboard</li>
                    </ul>
                    <p className="modelheading">Supported Channels</p>
                    <ul className="prcingSocial">
                      <li><img src="pages/images/homeicon1.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon2.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon3.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon4.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon5.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon6.png" alt='icon' /></li>
                    </ul>
                    <ul className="prcingSocial">
                      <li><img src="pages/images/homeicon7.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon8.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon9.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon10.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon11.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon12.png" alt='icon' /></li>
                    </ul>
                    <div className="onetime"><a href='getstarted'>Get Started</a></div>
                  </div>
                </div>
                <div className="priceModel">
                  <div className="priceType platinummodel"></div >
                  <div className="priceAmountBlock">
                    <p className="modelheading">Features</p>
                    <ul className="pricinglist">
                      <li><img src='pages/images/right.svg' alt='icon' className="tickmark" /> Self Brand Analysis</li>
                      <li><img src='pages/images/right.svg' alt='icon' className="tickmark" />Competitor Analysis</li>
                      <li><img src='pages/images/right.svg' alt='icon' className="tickmark" />STAC Analysis</li>
                      <li><img src='pages/images/right.svg' alt='icon' className="tickmark" />CASE Management</li>
                      <li><img src='pages/images/right.svg' alt='icon' className="tickmark" />Recommendations & SWOT Analysis Report</li>
                      <li><img src='pages/images/right.svg' alt='icon' className="tickmark" />Campaign Reporting</li>
                      <li><img src='pages/images/right.svg' alt='icon' className="tickmark" />Mentions & Hashtags</li>
                      <li><img src='pages/images/right.svg' alt='icon' className="tickmark" />Keyword based live dashboard</li>
                    </ul>
                    <p className="modelheading">Supported Channels</p>
                    <ul className="prcingSocial">
                      <li><img src="pages/images/homeicon1.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon2.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon3.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon4.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon5.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon6.png" alt='icon' /></li>
                    </ul>
                    <ul className="prcingSocial">
                      <li><img src="pages/images/homeicon7.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon8.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon9.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon10.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon11.png" alt='icon' /></li>
                      <li><img src="pages/images/homeicon12.png" alt='icon' /></li>
                    </ul>
                    <div className="onetime"><a href='getstarted'>Get Started</a></div>
                  </div>
                </div>
              </div>
              <div className="cost">
                <p className="modelheading">Additional Costs *</p>
                <ul className="pricinglist">
                  <li>One time Set-Up cost 12$/1GB</li>
                  <li>Historical Data of past 6 months</li>
                  <li>Dedicated account manager</li>
                </ul>
              </div>




            </Tab>
            <Tab eventKey="profile" title="Partner with Us">
              <div className="priceBox">
                <div className="">
                  <p className="priceTitle">For Marketing Agencies</p>
                  <p className="priceText">Attention Marketing Agencies <img src="pages/images/symbolic.svg" alt="" /></p>
                  <div className="priceDescriotion">
                    <p>
                      Supercharge your business growth & data-driven success with evalmyBRAND's cutting-edge
                      AI-driven collaboration tools! <span>#PartnerWithUs</span></p>
                    <p>Looking for ways to expand your client services and
                      stay ahead of the curve? Look no further than our
                      tailored partnership package, designed especially for
                      innovative marketing and digital agencies like yours.</p>
                    <p>
                      With evalmyBRAND, you'll enjoy streamlined
                      onboarding, hands-on platform assistance, flexible
                      pricing structures, comprehensive reports, and indepth data analysis. Provide your clients with a
                      powerful platform to understand their customers
                      and accelerate business growth using our diverse
                      AI-driven capabilities.</p>
                    <p>Partner with us today and take your client services
                      to the next level! <span>#EmpowerYourAgency</span>
                    </p>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
          {/* <div className="row">
            <div className="col-md-6 priceBox">
              <div className="priceInnerBox">
                <p className="priceTitle">For Marketing Agencies</p>
                <p className="priceText">Attention Marketing Agencies <img src="pages/images/symbolic.svg" alt="" /></p>
                <div className="priceDescriotion">
                  <p style={{ textAlign: 'center' }}>
                    Supercharge your business growth & data-driven success with evalmyBRAND's cutting-edge
                    AI-driven collaboration tools! <span>#PartnerWithUs</span></p>
                  <p>Looking for ways to expand your client services and
                    stay ahead of the curve? Look no further than our
                    tailored partnership package, designed especially for
                    innovative marketing and digital agencies like yours.</p>
                  <p>
                    With evalmyBRAND, you'll enjoy streamlined
                    onboarding, hands-on platform assistance, flexible
                    pricing structures, comprehensive reports, and indepth data analysis. Provide your clients with a
                    powerful platform to understand their customers
                    and accelerate business growth using our diverse
                    AI-driven capabilities.</p>
                  <p>Partner with us today and take your client services
                    to the next level! <span>#EmpowerYourAgency</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 priceBox">
              <div className="priceInnerBox">
                <p className="priceTitle">For Brands (B2B/B2C/D2C)</p>
                <p className="priceText">Are you a brand operating in the B2C, D2C, or B2B sectors?</p>
                <div className="priceDescriotion">
                  <p style={{ textAlign: 'center' }}>
                    Take your customer experience to the next level
                    with evalmyBRAND's affordable pricing bundle!
                    <span> #EmpowerYourBrand</span></p>
                  <p>
                    Our tailored program empowers brands to leverage
                    the power of customer experience management and
                    business insights without breaking the bank. With
                    intuitive technology, we help you place customer
                    experience at the heart of your brand values, making
                    the learning process streamlined and
                    straightforward.</p>
                  <p>No matter where you are on your growth journey,
                    we'll adapt to your current stage and support your
                    progress every step of the way partner with
                    evalmyBRAND today and unlock the full potential of
                    your customer experience! <span>#EmpowerYourBrand</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="text-center priceContact">
              <p><strong>Contact Us</strong></p>
              <div>
                For sales enquiries, email us to <span>sales@evalmybrand.com</span>
              </div>
              <div>
                For business enquiries, write us to <span>contact@evalmybrand.com</span>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div className="footer">
        {/* <div className="social-img"></div> */}
        <div className="container" style={{ paddingBottom: '40px' }}>
          <div className="row ">
            <div className="col-lg-2"></div>
            <div className="col-lg-6">
              <div className="d-flex br">
                <img
                  src="pages/images/gdpr.png"
                  alt="gdprlogo"
                  className="br-logo"
                />
                <img
                  src="pages/images/iso.png"
                  alt="isologo"
                  className="br-logo"
                />
                <img
                  src="pages/images/soc.png"
                  alt="soclogo"
                  className="br-logo"
                />
                <img
                  src="pages/images/skyhigh.png"
                  alt="skyhighlogo"
                  className="br-logo"
                />
              </div>
            </div>
          </div>
          <div className="row   justify-content-center mt-5">
            <div className="col-lg-3 col-md-6">
              <div className="s-brand">
                <a href="/">
                  <img
                    src="pages/images/evalmybrand.png"
                    className="s-img"
                    alt="evalmyBRANDmoblogo"
                  />
                </a>
                <p className="mission mt-2 mb-2">
                  Our mission is to empower you with the knowledge and tools you need to navigate the rapidly changing landscape of social media and customer experience.
                </p>
                <div className="social-imp">
                  <a
                    href="https://www.facebook.com/people/evalmyBRAND/100086047461629/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="pages/images/social-facebook.png"
                      alt="facebooklogo"
                      className="social"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/evalmybrand/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="pages/images/social-linkedin.png"
                      alt="linkedinlogo"
                      className="social"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/evalmybrand/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="pages/images/social-insta.png"
                      alt="instagramlogo"
                      className="social"
                    />
                  </a>
                  <a href=" https://twitter.com/evalmyBrand" target="_blank">
                    <img
                      src="pages/images/social-twitter.png"
                      alt="twitterlogo"
                      className="social"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCOs9P0nxGKD8uCDWWHRxgkw/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="pages/images/social-youtube.png"
                      alt="youtubelogo"
                      className="social"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="main-detailes mb-3" id="main-m">
                <a href="https://evalmybrand.com/">
                  <h3>Company</h3>
                </a>
                <a href="/about">
                  <p>About evalmyBRAND</p>
                </a>
                <a href="/pricing">
                  <p>Pricing</p>
                </a>
                <a href="https://blog.evalmybrand.com/" target="_blank" rel="noreferrer" >
                  <p>News & Blog</p>
                </a>
                <a href="/#favourite">
                  <p >Customers</p>
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="main-detailes">
                <a href="features">
                  <h3>Features</h3>
                </a>
                <a href="features#insight">
                  <p>Insights & Analytics</p>
                </a>
                <a href="features#sociallistening">
                  <p>Social Listening</p>
                </a>
                <a href="features#socialmediamonitoring">
                  <p>Social Media Monitoring</p>
                </a>
                <a href="features#appanalysis">
                  <p>App Analysis</p>
                </a>
                <a href="features#campaignanalyzer">
                  <p>Campaign Analyzer</p>
                </a>
                <a href="features#casemanagement">
                  <p>Case Management</p>
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="main-detailes mb-5">
                <h3>Support & Legal</h3>
                <a href="/faqs" target="_blank" rel="noreferrer" >
                  <p>FAQs</p>
                </a>
                <a href="/getstarted">
                  <p>Contact Us</p>
                </a>
                <a href="/terms" target="_blank" className="nav-link active">
                  <p className="d-1">Terms of Use</p>
                </a>
                <a href="/policies" target="_blank" className="nav-link active">
                  <p className="d-1">Privacy Policy</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr style={{ marginBottom: '0.5rem' }} />
        <div className="container">
          <div className="footer-detailes">
            <div className="d-flex detailes">
              <div className="copy">
                <p style={{ marginBottom: '0.5rem' }}>© evalmyBRAND 2023. All Rights Reserved </p>
                <p style={{ display: "none" }}>01.09</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Pricing;
