import React from "react";
// import { HashLink as Link } from "react-router-hash-link";
import { Helmet } from "react-helmet";
function Policies() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>evalmyBRAND's Moto & Vision | evalmyBRAND </title>
        <meta
          name="description"
          content="evalmyBRAND is unified social listening platform which helps brands to improve the targeting accuracy and automates the management of social media communications."
        />
        <meta name="keywords" content="evalmyBRAND,About evalmyBRAND" />
      </Helmet>

      <div id="about" title="aboutevalmyBRAND">

<div className="home-page-section positon-sticky" id="header">
  <nav className="navbar navbar-expand-lg" id="navbar">
    <div className="container-fluid">
      <a className="navbar-brand" href="https://evalmybrand.com/">
        <img
          src="pages/images/evalmybrand-Logo.png"
          alt="evalmyBRANDlogo"
        />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse"
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          {/* <li className="nav-item">
            <a
              className="nav-link"
              aria-current="page"
              href="https://evalmybrand.com/"
            >
              Home
            </a>
          </li> */}
          <li className="nav-item">
            <a className="nav-link" href="/about">
              About Us
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link "
              href="features"
            >
              Features
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link"
              href="/pricing"
            >
              Pricing
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="https://blog.evalmybrand.com/"
              target="_blank"
              rel="noreferrer"
            >
              News & Blog
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/getstarted">
              Contact Us
            </a>
          </li>
        </ul>
        <div className="nav-btns d-flex hide">
          <a
            href="https://portal.evalmybrand.com"
            className="mt-1 nav-link active"
            target="_blank"
            rel="noreferrer"
          >
            Login
          </a>
          {/* <a href="/sales">
          <button className="btn btn-primary">Request Demo</button>
        </a> */}
        </div>
      </div>
    </div>
  </nav>
  {/* <nav className="navbar navbar-expand-lg" id="navbar-mob">
    <div className="container-fluid">
      <a className="navbar-brand-mob" href="/">
        <img
          src="/pages/images/logo-brand-white.png"
          alt="evalmyBRANDlogo"
        />
      </a>

      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse"
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <a className="nav-link" href="/">
              Home
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/features">
              Features
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link active" href="/about">
              About Us
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="https://blog.evalmybrand.com/"
              target="_blank"
            >
              Blog
            </a>
          </li>
        </ul>
        <div className="m-btns d-flex hide">
          <a
            href="https://portal.evalmybrand.com"
            className="mt-1 nav-link active"
            target="_blank"
          >
            Login
          </a>
        </div>
      </div>
    </div>
  </nav> */}
</div>
</div>
      <div class="terms">
        <div class="container">
          <h1 class="text-center mt-5 mb-5">PRIVACY POLICY</h1>
          <p class="mb-4 text-justify">
            Hello! We appreciate your time to have a look into evalmyBRAND’s
            privacy policy. evalmyBRAND and its affiliates work with great
            compassion and responsible for securing our customers Personal
            Information. We keep in mind our believes and value system that
            includes passion, motivation, obsession and enthusiasm on doing what
            is right for our customers which reflects in our business operations
            and development of our product and services This Privacy
            Policy(“Policy”) contemplates on How/Why/What are our practices on
            gathering the personal information while accessing our
            website/portal. We recommend you have a look at our privacy policy
            in case of any changes made or you can contact us on [email] to get
            any specific information.
          </p>
          <p class="mt-2 mb-2  text-justify">
            To protect your privacy(of account and data), evalmyBRAND follows
            different principles in accordance with worldwide practices for
            customer privacy and data protection.
          </p>
          <dl class='text-justify'>
            <dd>
              <span class="te">&#x274F;</span>You may use the Service only if
              You can form a binding contract with Us, and only in compliance
              with this Agreement and all applicable local, state, national, and
              international laws, rules and regulations.
            </dd>
            <dd>
              <span class="te">&#x274F;</span>By clicking on the "Accept" button
              at the end of the Agreement acceptance form, Users agree to be
              bound by the Terms and Conditions of this Agreement. Please read
              this entire Agreement carefully before accepting its Terms. When
              You undertake any activity on the Website/ Portal,You agree to
              accept these Terms and Conditions.{" "}
            </dd>
          </dl>

          <h5 class="mt-4 mb-3  text-justify">NOTICE</h5>
          <p class='text-justify'>
            {" "}
            We will ask you when we need information that personally identifies
            you (personal information) or allows us to contact you. Generally,
            this information is requested when you create a Registration ID on
            the site/portal or when you order/subscribe email newsletters or
            blog posts. We use your Personal Information for four primary
            purposes:
          </p>
          <dl class="text-justify">
            <dd>
              <span class="te">&#x274F;</span>To make the site/portal easier for
              you to use by not having to enter information more than once.{" "}
            </dd>
            <dd>
              <span class="te">&#x274F;</span>To help you quickly find software,
              services or information.{" "}
            </dd>
            <dd>
              <span class="te">&#x274F;</span>To help us create content most
              relevant to you.
            </dd>
            <dd>
              <span class="te">&#x274F;</span>To alert you to product upgrades,
              special offers, updated information and other new services from
              evalmyBRAND.
            </dd>
          </dl>

          <h5 class="mt-4 mb-3  text-justify">CONSENT</h5>
          <p class="text-justify">
            If you choose not to register or provide personal information, you
            can still use https://evalmybrand.com But you will not be able to
            access areas that require registration and subscription of the
            services offered by the company.
          </p>
          <p class="text-justify">
            If you do not want us to communicate with you about other offers
            regarding evalmyBRAND products, programs, events, or services by
            email, postal mail, or telephone, you may select the option stating
            that you do not wish to receive marketing messages from evalmyBRAND.
          </p>
          <p class="text-justify">
            evalmyBRAND occasionally allows other companies to offer our
            registered customers information about their products and services,
            using postal mail only. If you do not want to receive these offers,
            you may select the option stating that you do not wish to receive
            marketing materials from third parties.{" "}
          </p>

          <h5 class="mt-4 mb-3">ACCESS</h5>
          <p class="text-justify">
            {" "}
            We will provide you with the means to ensure that your personal
            information is correct and current. You may review and update this
            information at any time on the My Account section of the portal
            accessed by you. There, you can:{" "}
          </p>
          <dl class="text-justify">
            <dd>
              <span class="te">&#x274F;</span>View and edit personal information
              you have already given us.{" "}
            </dd>
            <dd>
              <span class="te">&#x274F;</span>Tell us whether you want us to
              send you marketing information, or whether you want third parties
              to send you their offers by postal mail.{" "}
            </dd>
            <dd>
              <span class="te">&#x274F;</span>Sign up for electronic
              newsletters/blogs about our services and products.{" "}
            </dd>
            <dd>
              <span class="te">&#x274F;</span>Register. Once you register, you
              won't need to do it again. Wherever you go on
              https://evalmybrand.com, your information stays with you.{" "}
            </dd>
          </dl>

          <h5 class="mt-4 mb-3">SECURITY</h5>
          <p class="text-justify">
            evalmyBRAND has taken strong measures to protect the security of
            your social insights & analytics represented on the portal as per
            the subscription availed by the user, personal information and to
            ensure that your choices for its intended use are honored. We take
            strong precautions to protect your data from loss, misuse,
            unauthorized access or disclosure, alteration, or destruction.{" "}
          </p>
          <p class="text-justify">
            When youaccess your account information on the portal, you're
            utilizing secure server software SSL, which encrypts your personal
            information before it's sent over the Internet. SSL is one of the
            safest encryption technologies available.
          </p>
          <p class="text-justify">
            evalmyBRAND strictly protects the security of your personal
            information and honors your choices for its intended use. We
            carefully protect your data from loss, misuse, unauthorized access
            or disclosure, alteration, or destruction.{" "}
          </p>
          <p class="text-justify">
            Inside the company, data is stored in password-controlled servers
            with limited access. Your information may be stored and processed in
            India or any other country where evalmyBRAND,its subsidiaries,
            affiliates or agents are located.
          </p>
          <p class="text-justify">
            You also have a significant role in protecting your information. No
            one can see or edit your personal information without knowing your
            username and password, so do not share these with others.
          </p>

          <h5 class="mt-4 mb-3">ENFORCEMENT</h5>
          <p class="text-justify">
            If for some reason you believe evalmyBRAND has not adhered to these
            principles, please notify us by email at contact@evalmybrand.com,
            and we will do our best to determine and correct the problem
            promptly. Be certain the words Privacy Policy are in the Subject
            line.
          </p>

          <h5 class="mt-4 mb-3"> REGISTRATION </h5>
          <p class="text-justify">
            When you subscribe and access products/services offered by
            evalmyBRAND, we may ask you to register your subscription. If you
            haven't previously registered with us, we may create an invite for
            you to complete the registration process and access the portal
            accordingly. If you ever want to review or update that information,
            you can visit the My Account section of the portal accessed by you.
            If you haven't already created a Registration ID, we will ask you to
            do so. This ensures that only you can access your information.
          </p>

          <h5 class="mt-4 mb-3"> CUSTOMER PROFILES</h5>
          <p class="text-justify">
            As mentioned above, every registered customer has a unique personal
            profile. Each profile is assigned a unique personal identification
            number, which helps us ensure that only you can access your profile.
          </p>
          <p class="text-justify">
            When you register, we create your profile, assign a personal
            identification number, then send this personal identification number
            back to your hard drive in the form of a cookie, which is a very
            small bit of code. This code is uniquely yours. It is your passport
            to seamless travel across the portal[portal.evalmybrand.com],
            allowing you to accessyour account. Even if you switch computers,
            you won't have to re-register – just use your account credentials to
            identify yourself.{" "}
          </p>

          <h5 class="mt-4 mb-3">WHAT WE DO WITH THE INFORMATION YOU SHARE</h5>
          <p class="text-justify">
            When you join us, you provide us with your contact information,
            including your email address. We use this information to send you
            updates about your account’s social insights & analytics,
            questionnaires and survey’s to measure your satisfaction with our
            service and announcements about new and exciting services that we
            offer. For your convenience, we do save mailing/billing information
            in case you want to upgrade from us again, but we don't use this
            information again without your permission.
          </p>
          <p class="text-justify">
            evalmyBRAND will disclose your personal information, without notice,
            only if required to do so by law or in the good faith belief that
            such action is necessary to: (a) conform to the edicts of the law or
            comply with legal process served on evalmyBRAND or the site/portal;
            (b) protect and defend the rights or property of evalmyBRAND and its
            family of Websites/portal, and, (c) act in urgent circumstances to
            protect the personal safety of users of evalmyBRAND, its
            Websites/portal.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Policies;
