// import logo from './logo.svg';
import './App.css';
import Home from './pages/home';
import About from './pages/about';
// import Brand from './pages/brand';
// import Casemanagement from './pages/casemanagement';
// import Compitetor from './pages/compitetor';
// import Engagement from './pages/engagement';
// import Insight from './pages/insight';
import Sales from './pages/sales';
import Faqs from './pages/faqs'
// import Sentiment from './pages/sentiment';
// import Sign from './pages/sign';
import React from "react"; 
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Thankyou from './pages/thankyou';
import Terms from './pages/terms';
import Policies from './pages/policies';
import Products from './pages/products';
import Pricing from './pages/pricing';

function App() {
  return (
    
<Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/#products" element={<Home />} />
        <Route path="/about" element={<About />} />
        {/* <Route path="/brandgoal" element={<Brand />} /> 
        <Route path="/casemanagement" element={<Casemanagement />} />
        <Route path="/competitor-analysis" element={<Compitetor />} />
        <Route path="/engagement" element={<Engagement />} />
        <Route path="/insights-analytics" element={<Insight />} />
        <Route path="/sentiment-analysis" element={<Sentiment />} /> */}
        <Route path="/getstarted" element={<Sales />} />
        {/* <Route path="/sign" element={<Sign />} /> */}
        {/* <Route path="/thankyou" element={<Thankyou />} /> */}
        <Route path="/terms" element={<Terms />} />
        <Route path="/policies" element={<Policies />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/features" element={<Products />} />
        <Route path="/pricing" element={<Pricing />} />
      </Routes>
    </Router>
  );
}

export default App;
